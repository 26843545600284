const axios = require('axios')
const { default: authHeader } = require('./auth-header')

const averageDriverRating = async (driver_id) => {
  const response = await axios.get(`/ratings/drivers/${driver_id}`, { headers: authHeader() })
  const ratings = response.data.data
  let sum = 0
  for (let i = 0; i < ratings.length; i++) {
    sum += ratings[i].star
  }
  return sum / ratings.length
}

module.exports = averageDriverRating
