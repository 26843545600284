
import axios from 'axios'
import FeatureFlagService from '@/services/feature-flags'
import { authorize } from '@/services/api-service'
import { setZohoUser } from "@/services/zoho";

const state = {
  user: null,
  is_authenticated: false,
  auth_error: null
}

const getters = {
  isAuthenticated: state => state.is_authenticated,
  getUser: state => state.user,
  getAuthError: state => state.auth_error
}

const actions = {
  async LogIn({ commit, dispatch }, user) {
    try {
      const response = await axios.post('login', user)
      setZohoUser(response.data)
      dispatch('setUserContext', response.data);
    } catch (error) {
      throw error
    }
  },
  async LogInViaOtp({ commit, dispatch }, payload) {
    try {
      const data = { otp_code: payload.otp_code, type: 'user' };
      const response = await axios.patch(`/otp-login/${payload.otp_ref}`, data)
      setZohoUser(response.data)
      dispatch('setUserContext', response.data);
    } catch (error) {
      throw error
    }
  },
  LogOut({ commit }) {
    const user = null
    commit('LogOut', user)
    window.$zoho.salesiq.reset();
  },
  setAuthError({ commit }, error_message) {
    commit('setAuthError', error_message)
  },
  setAuthentication({ commit }, value) {
    commit('setAuthentication', value)
  },
  setUserContext({ commit, dispatch }, user) {
    if (user) {
      setZohoUser(user)
      dispatch('setAuthentication', true)
      commit('setUser', user);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('user_id', user.id);
      localStorage.setItem('user_token', user.token.token);
      authorize(user.token.token);
      dispatch('ping/getAllPingMessages');
      FeatureFlagService.setCurrentUserData({ email: user?.email, userId: user?.id });
    } else {
      commit('LogOut');
    }
  },
  setUser({ commit }, user) {
    commit('setUser', user);
  }
}

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  LogOut(state) {
    localStorage.clear()
    window.$zoho.salesiq.reset();
    state.user = null
    state.is_authenticated = false
  },
  setAuthentication(state, value) {
    state.is_authenticated = value
  },
  setAuthError(state, error_message) {
    state.auth_error = error_message
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
