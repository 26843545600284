<template>
    <div>
        <KeepAlive>
            <component
                v-bind:is="steps[currentStep]"
                @guest-info-collected="next"
                @paying-wallet-selected="next"
                @wallet-type-selected="next"
                @user-info-collected="jump"
                @pickup-dropoff-selected="next"
                @previous="goBack"
                @user-is-guest="next"
            ></component>
        </KeepAlive>
    </div>
</template>

<script>
import PassengerDetailsVue from '@/components/booking-steps/PassengerDetails.vue'
import SelectWalletPage from '@/components/booking-steps/SelectWalletPage.vue'
import OtpPage from '@/components/booking-steps/OtpPage.vue'
import PaymentPage from '@/components/booking-steps/PaymentPage.vue'
import GuestDetails from '@/components/booking-steps/GuestDetails.vue'
import SelectLocationPageVue from '@/components/booking-steps/SelectLocationPage.vue'

import { markRaw } from '@vue/reactivity'
import {mapGetters} from 'vuex';
export default {
  name: 'BookForSomeoneWizard',
  data () {
    return {
      currentStep: 0,
      steps: [
        PassengerDetailsVue,
        GuestDetails,
        SelectLocationPageVue,
        SelectWalletPage,
        PaymentPage,
        OtpPage
      ]
    }
  },
  components: {
    PassengerDetailsVue: markRaw(PassengerDetailsVue),
    OtpPage: markRaw(OtpPage),
    PaymentPage: markRaw(PaymentPage),
    SelectWalletPage: markRaw(SelectWalletPage),
    GuestDetails: markRaw(GuestDetails),
    SelectLocationPageVue: markRaw(SelectLocationPageVue)
  },
  mounted() {
    if(!this.getTripToBook?.id) {
      this.$router.replace('/home');
    }
  },
  computed: {
    ...mapGetters(['getTripToBook']),
  },
  methods: {
    next () {
      this.currentStep += 1
    },
    goBack () {
      if (this.currentStep === 2) this.currentStep = 0
      else this.currentStep -= 1
    },
    jump () {
      this.currentStep += 2
    }
  }
}
</script>
