<template>
  <router-view>

  </router-view>
</template>

<script>
import Vue from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './components/Header.vue'
import Login from './pages/Login.vue'
import AppHome from './pages/AppHome.vue'

export default {
  name: 'App',
  components: {
    Header,
    Login,
    AppHome
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&family=Inter:wght@700&family=Roboto:wght@500&display=swap');
@import "toastr";

*{
  --form-desctiption-color : #575A65;
  --black-btn-bg-color: #000005;
  --green-btn-bg-color: #20E682;

  --shuttlers-nuetral-900: #101211;
  --shuttlers-green:#0DAC5C;

  /* custom fonts */
  --font-heebo: 'Heebo', sans-serif;
  --font-inter: 'Inter', sans-serif;
  --font-roboto: 'Roboto', sans-serif;

  font-family: var(--font-heebo);
}
.flex-row-aacjjc{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-row-space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-row-center-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row{
  display: flex;
}
.flex-col{
  display: flex;
  flex-direction: column;
}
.selector-for-some-widget {
  box-sizing: content-box;
}
#app{
  max-width: 400px;
  margin: 0 auto;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  position: relative;
}

</style>
