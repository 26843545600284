<template>
    <page-title-emit @previous="$emit('previous')" :text="`Select payment method`"/>
    <div class="booking-notice">
        <div class="flex-row align-start">
            <p class="message-large">Booking fee for this trip costs <span class="green-notice">&nbsp;&#8358;{{getTripToBook.itinerary.default_fare}}</span> </p>
        </div>
    </div>

    <div class="main-balance wallet-item personal_wallet" @click="selectMainWallet($event)">
        <div class="flex-row-space-between">
            <div class="flex-row">
                <img class="wallet-list-icon" src="@/assets/icons/wallet-list-icon.svg"/>
                <div>
                    <div class="wallet_type">Personal Wallet</div>
                    <div class="wallet_balance">
                        <span v-if="parseFloat(getPayingWallet.main_balance) >= parseFloat(getTripToBook.itinerary.default_fare)" style="color: green;">Sufficient balance</span>
                        <span v-else style="color: #E13D45">Insufficient balance</span>
                    </div>
                </div>
            </div>
            <img v-if="form.payment_source === 'main_balance'" src='@/assets/icons/selected-wallet.svg'/>
            <img v-else src='@/assets/icons/not-selected-wallet.svg'/>
        </div>
    </div>

   <div
        v-if="getCompanyWallet()"
        class="credit_amount wallet-item company_wallet"
        @click="selectCompanyWallet($event)"
    >
        <div class="flex-row-space-between">
            <div class="flex-row">
                <img class="wallet-list-icon" src="@/assets/icons/wallet-list-icon.svg"/>
                <div>
                    <div class="wallet_type">Company Wallet</div>
                    <!-- <div class="wallet_balance">N{{getUserToBook?.wallet?.credit_amount}}</div> -->
                    <div class="wallet_balance">
                        <span v-if="parseFloat(getPayingWallet.credit_amount) >= parseFloat(getTripToBook.itinerary.default_fare)" style="color: green;">Sufficient balance</span>
                        <span v-else style="color: #E13D45">Insufficient balance</span>
                    </div>
                </div>
            </div>
            <img v-if="form.payment_source === 'credit_balance'" src='@/assets/icons/selected-wallet.svg'/>
            <img v-else src='@/assets/icons/not-selected-wallet.svg'/>
        </div>
  </div>

   <button
        type="submit"
        class="booking-btn"
        @click="onSubmit"
        :class="wallet_selected && sufficient_wallet_selected ? 'green-button': 'disabled-button'"
        :disabled="!wallet_selected || !sufficient_wallet_selected"
    >
        <span v-if="!loading">
            <span>Book Trip ₦{{getTripToBook.itinerary.default_fare}}</span>
        </span>
        <span v-else class="spinner-border text-light" role="status">
        </span>

    </button>

</template>

<script>
import Header from '@/components/Header.vue'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import PageTitle from '@/components/PageTitle.vue'
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import { ref } from 'vue'
import PageTitleEmit from '../PageTitleEmit.vue'
import authHeader from '@/services/auth-header'

export default {
  name: 'PaymentPage',
  emits: [
    'user-info-collected',
    'previous',
    'paying-wallet-selected',
    'wallet-type-selected',
    'guest-info-collected',
    'user-is-guest',
    'pickup-dropoff-selected'
  ],
  setup () {
    const isOpen = ref(false)

    return { isOpen }
  },
  data () {
    return {
      form: {
        route_id: null,
        itinerary_id: null,
        pickup_id: null,
        destination_id: null,
        start_date: '',
        end_date: '',
        days_ids: [],
        recurring: false,
        meta: '',
        luggage_quantity: 1,
        payment_source: ''
      },
      wallet_selected: false,
      ref: '',
      loading: false,
      showPassword: false,
      is_email_login: true,
      startMinute: 10,
      time: 600,
      trip: {},
      sufficient_wallet_selected: false
    }
  },
  computed: {
    ...mapGetters([
      'getTripToBook',
      'getPayingWallet',
      'getPayingUser',
      'getBookingUserDetails',
      'getUser',
      'pickup-dropoff-selected'
    ])
  },
  created () {
    this.setBookForUserDetails()
    // this.setClickedTrip()
  },
  methods: {
    ...mapActions([
      'setBookingReference',
      'setPaymentSource'
    ]),
    async onSubmit () {
      this.loading = true
      try {
        // TODO (Daniel): Review all axios integrations in the application
        const response = await axios.post(
          `/bus-captains/users/${this.getBookingUserDetails?.id}/otps`,
          {},
          { headers: authHeader() })
        this.setBookingReference(response.data.reference_id)
        this.$emit('wallet-type-selected')
      } catch (error) {
        this.$toast.warning(error.response?.data?.message || 'An error occurred')
      } finally {
        this.loading = false
      }
    },
    checkIfWalletIsSufficient () {
      if (this.form.payment_source === 'main_balance') {
        if (parseFloat(this.getPayingWallet.main_balance) < parseFloat(this.getTripToBook.itinerary.default_fare)) {
          this.sufficient_wallet_selected = false
          this.$toast.warning('Selected wallet has insufficient fund')
        } else this.sufficient_wallet_selected = true
      } else {
        if (parseFloat(this.getPayingWallet.credit_amount) < parseFloat(this.getTripToBook.itinerary.default_fare)) {
          this.sufficient_wallet_selected = false
          this.$toast.warning('Selected wallet has insufficient fund')
        } else this.sufficient_wallet_selected = true
      }
    },
    selectMainWallet (event) {
      this.wallet_selected = true
      this.form.payment_source = 'main_balance'
      this.setPaymentSource('main_balance')
      this.checkIfWalletIsSufficient()
    },
    selectCompanyWallet (event) {
      this.wallet_selected = true
      this.form.payment_source = 'credit_balance'
      this.setPaymentSource('credit_balance')
      this.checkIfWalletIsSufficient()
    },
    setBookForUserDetails () {
      this.form.route_id = this.getClickedTrip?.route_id
      this.form.itinerary_id = this.getClickedTrip?.userRoute?.itinerary_id
      this.form.pickup_id = this.getClickedTrip?.userRoute?.pickup_id
      this.form.destination_id = this.getClickedTrip?.userRoute?.destination_id
      this.form.start_date = this.getClickedTrip?.userRoute?.start_date
      this.form.end_date = this.getClickedTrip?.userRoute?.end_date
      this.form.days_ids = [this.getClickedTrip?.day_id]
      this.form.meta = this.getClickedTrip?.userRoute?.meta
      this.form.luggage_quantity = 1
    },
    redirectToTripDetailsPage () {
      // router.push({ name: 'TripDetail', params: { tripId: `${this.getClickedTrip.userRoute.id}` } })
    },
    goToOtpPage () {
      this.$emit('wallet-type-selected')
    },
    getCompanyWallet () {
      return !!(this.getPayingUser === 'passenger' && this.getBookingUserDetails.corporate_id ||
                    this.getPayingUser === 'bus-captain' && this.getUser.corporate_id)
    }
  },
  components: { Header, PageTitle, Modal, PageTitleEmit }
}
</script>

<style lang="scss">
    .wallet-item{
        background: #FFFFFF;
        border: 1px solid #E5E9F2;
        border-radius: 8px;
        padding: 16px;
        height: 74px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .wallet-list-icon{
        margin-right: 14px;
    }

    .wallet_type{
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        display: flex;
        color: #000005;
    }

    .wallet_balance{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        color: $sh-neutral-700;
    }

    .personal_wallet{
        margin-top: 24px;
    }

    .company_wallet{
        margin-top: 20px;
    }
    .bus-icon{
        display: block;
        margin: 0 auto;
    }
    .to-trip-manifest{
        margin-top: 24px;
    }
    .green-notice{
        color: $sh-green-700;
    }
</style>
