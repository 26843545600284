<template>
  <div
    v-if="getLoadingState"
    class="d-flex flex-column py-5 justify-content-center"
  >
    <div class="spinner-border mx-auto mb-3" role="status">
      <span class="sr-only"></span>
    </div>
    <p class="mx-auto text-center">Loading Passengers</p>
  </div>
  <div v-else>
    <div v-if="userTrips" class="active-trip-page">
      <page-title text="Active Trip" />
      <br />
      <pickup-dropoff
        :from="userTrips[0]?.route?.pickup"
        :to="userTrips[0]?.route?.destination"
      />
      <br />

      <!-- <CaptainTab/> -->
      <div class="tab-bg flex-row-space-between">
        <span
          @click="swapTabs(getSelectedTab.current_tab)"
          v-bind:class="getSelectedTab.selected0"
          class="tab-item tab1"
          >Pick up</span
        >
        <span
          @click="swapTabs(getSelectedTab.current_tab)"
          v-bind:class="getSelectedTab.selected1"
          class="tab-item tab2"
          >Drop off</span
        >
      </div>

      <div v-if="userTrips[0]?.trip_id" class="active-trip-alert start-alert">
        <img :src="green_dot" />
        Trip has started
      </div>

      <div v-else class="active-trip-alert end-alert">
        <img :src="red_dot" />
        Trip has ended
      </div>

      <div v-bind:class="getSelectedTab.display0" class="manifest-tab">
        <CICOPage :passengers="allPassengers" />
      </div>

      <div v-bind:class="getSelectedTab.display1" class="breakdown-tab">
        <DropOffPage :passengers="allPassengers" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PickupDropoff from "@/components/PickupDropoff.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import BreakDown from "@/components/BreakDown.vue";
import Breakdown2 from "../components/Breakdown2.vue";
import DriverBusDetails from "../components/DriverBusDetails.vue";
import CaptainTab from "@/components/CaptainTab.vue";
import CICOPage from "@/components/CICOPage.vue";
import DropOffPage from "../components/DropOffPage.vue";
import green_dot from "../assets/icons/green-dot.svg";
import red_dot from "../assets/icons/red-dot.svg";
import axios from "axios";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    PageTitle,
    PickupDropoff,
    BreakDown,
    Breakdown2,
    SectionHeader,
    DriverBusDetails,
    CaptainTab,
    CICOPage,
    DropOffPage,
  },
  name: "ActiveTripPage",
  computed: mapGetters([
    "getSelectedTab",
    "allPassengers",
    "userTrips",
    "getLoadingState",
  ]),
  data() {
    return {
      green_dot: green_dot,
      red_dot: red_dot,
      isTripActive: true,
      tab: 0,
    };
  },
  created() {
    this.resetTabs();
    this.fetchUserTrips();
    const route_id = this.$route.params.routeId;
    const trip_date = this.$route.params.tripDate;
    const driver_id = this.$route.params.driverId;
    //  const trip_id = this.$route.params.tripId
    // get the passengers for this trips
    this.getTripPasengers({ route_id, trip_date, driver_id });
  },
  methods: {
    ...mapActions([
      "swapTabs",
      "resetTabs",
      "getTripPasengers",
      "fetchUserTrips",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.active-trip-page {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.active-trip-alert {
  width: 137px;
  height: 28px;
  margin: 24px auto 32px auto;
  border-radius: 24px;
  text-align: center;
  padding: 4px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
}
.start-alert {
  background: #edfdf5;
  color: #0dac5c;
}
.end-alert {
  background: #fcedee;
  color: #e13d45;
}
.show-block {
  display: block;
}
.hide-block {
  display: none;
}
hr {
  margin-top: 26px;
  margin-bottom: 32px;
  border: 1px solid #dfe0e3;
}

.selected-tab {
  background: #ffffff;
  /* Elevation 1 */

  box-shadow: 0px 2px 16px rgba(100, 100, 100, 0.1);
  border-radius: 6px;
  color: var(--shuttlers-green);
}
.tab-item {
  width: 49%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  /* Gray/White */
}
.tab-bg {
  height: 40px;
  /* Shuttlers Neutrals/100 */

  background: #f4f5f4;
  border-radius: 6px;
  align-items: center;
  padding-left: 3px;
  padding-left: 3px;
  margin-top: 24px;
  margin-bottom: 32px;
}
</style>
