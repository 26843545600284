<template>
    <span v-if="type==='secondary'" v-bind:class='secondayStyle'>
        {{text}}
    </span>
    <span v-else-if="type==='danger'" v-bind:class='dangerStyle'>
        {{text}}
    </span>
    <span v-else v-bind:class='positiveStyle'>
        {{text}}
    </span>
</template>

<script>
export default {
  name: 'ModalButton',
  props: {
    text: String,
    type: String
  },
  data () {
    return {
      secondayStyle: 'secondayStyle',
      dangerStyle: 'dangerStyle',
      positiveStyle: 'positiveStyle'
    }
  }
}
</script>

<style scoped>
    .secondayStyle{
        color: #101211;
        background-color: #E5E9F2;
    }
    .dangerStyle{
        color: #FFFFFF;
        background-color: #E13D45 ;
    }
    .positiveStyle{
        color: #000005;
        background-color: #20E682; ;
    }
    span{
        width: 47% ;
        height: 40px;
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: .875rem;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
