<template>
    <div v-for="(passengers, bus_stop, index) in trip_manifest" :key="index">
        <div class="flex-row busstop-and-passengers">
          <div class="col d-flex">
            <img class="shuttlers-location" :src="shuttlers_location"/>
            <div class="space-left">
              <p class="bus-stop"> {{bus_stop}} </p>
              <p class="passenger-per-bus-stop">Picking up {{trip_manifest[bus_stop].length}} passengers</p>
            </div>
          </div>
        </div>

        <div v-for="passenger in passengers" :key="passenger.id">
            <PassengerItem :passenger="passenger"/>
        </div>
    </div>
</template>

<script>
import shuttlers_location from '../assets/icons/shuttlers-location.svg'
import PassengerItem from './PassengerItem.vue'
import PingView from '@/components/PingView';
export default {
  name: 'Manifest',
  props: {
    trip_manifest: Object
  },
  data () {
    return {
      shuttlers_location: shuttlers_location,
    }
  },
  components: {PingView, PassengerItem }
}
</script>

<style lang="scss" scoped>
    .busstop-and-passengers{
        margin-bottom: 2rem;
    }
    .space-left{
        margin-left: 8px;
    }
    .shuttlers-location{
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 3px;
    }

    .bus-stop{
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 28px;
        color: #101211;
        margin-bottom: 0px;
    }
    .passenger-per-bus-stop{
        font-style: normal;
        font-weight: 400;
        font-size: 0.8125rem;
        line-height: 20px;
        /* Shuttlers Neutrals/600 */

        color: $sh-neutral-600;
      margin-bottom: 0;
    }
</style>
