<template>
    <div>
        <div class="flex-row flex-row-space-between">
            <div class="driver-bus-details flex-row">
                <div>
                    <img class="icon front" :src="trip_breakdown.route?.driver?.avatar ? trip_breakdown.route?.driver?.avatar : driver_img"/>  <!-- driver icon -->
                    <img class="v-icon overlap" :src="bus_icon"/>  <!-- bus icon -->
                </div>

                <div class="details-box">
                    <p class="driver-name">
                        {{trip_breakdown.driver?.fname}}
                        {{trip_breakdown.driver?.lname}}
                    </p>
                    <p>
                        <span class="star">
                            <span style="color: #0DAC5C;">&#9733;</span>
                            {{driver_rating}} &#183;
                            </span>
                        <!-- <span class="trip-no">1200 trips</span> -->
                    </p>
                </div>
            </div>

            <a :href="'tel:'+trip_breakdown.driver?.phone">
                <img class="icon-44" :src="phone_icon"/>
            </a>
        </div>

        <img/> <!-- call icon -->

    </div>
</template>

<script>
import driver_img from '../assets/icons/user-placeholder.svg'
import bus_icon from '../assets/icons/bus-placeholder.svg'
import call_icon from '../assets/icons/phone-icon.svg'
import router from '../router'
import averageDriverRating from '@/services/averageDriverRating'

export default {
  name: 'DriverBusDetails',
  props: {
    trip_breakdown: Object
  },
  data () {
    return {
      driver_img: driver_img,
      bus_icon: bus_icon,
      phone_icon: call_icon,
      averageDriverRating: averageDriverRating,
      driver_rating: '***'
    }
  },
  methods: {
    goToActiveTrip () {
      router.push({ path: '/active-trip' })
    }
  },
  created () {
    this.averageDriverRating(this.trip_breakdown.driver?.id).then(res => {
      if (res) this.driver_rating = res.toFixed(1)
    })
  }
}
</script>

<style scoped>
    .icon-44 {
        width: 44px;
        height: 44px;
    }
    .star{

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        color: #101211;
    }
    .trip-no {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #313533;
        flex: none;
    }
    .driver-name{
        /* Body Text/Standard - 16px/Medium */

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
        /* identical to box height, or 150% */
        /* Gray/Black 1 */

        color: #000005;

    }
    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .v-icon {
        width: 40px;
        height: 40px;
    }
    .front{
        order: 1;
    }
    .overlap{
        margin-left: -15px;
        order: 2;
    }
    .details-box{
        margin-left: 12px;
    }
</style>
