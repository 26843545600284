<template>
    <span v-if="type==='noshow-c'" v-bind:class='noShowStyle'>
        No show
    </span>
    <span v-else-if="type==='pickedup'" v-bind:class='pickedUpStyle'>
        Picked up
    </span>
    <span v-else v-bind:class='droppedOffStyle'>
        Dropped off
    </span>
</template>

<script>
export default {
  name: 'CICOTag',
  props: {
    type: String
  },
  data () {
    return {
      droppedOffStyle: 'droppedOffStyle',
      pickedUpStyle: 'pickedUpStyle',
      noShowStyle: 'noShowStyle'

    }
  }
}
</script>

<style scoped>
    .droppedOffStyle{
        color: #575A65;
        background-color: #EFF2F7;
    }
    .pickedUpStyle{
        color:#0DAC5C;
        background-color: #EDFDF5 ;
    }
    .noShowStyle{
        color: #E13D45;;
        background-color: #FCEDEE;;
    }

    span {
        gap: 10px;
        height: 28px;
        padding: 4px 12px;
        border-radius: 50px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
    }
</style>
