import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import axios from 'axios'
import toastr from 'toastr'
import authHeader from '@/services/auth-header'
import apiService, { authorize } from '@/services/api-service'

import 'bootstrap/dist/css/bootstrap.css'
import './registerServiceWorker'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import FeatureFlagService from '@/services/feature-flags'
import RemoteConfigService from '@/services/remote-config'
import { setZohoUser } from "@/services/zoho";
/* add icons to the library */
library.add(faEye, faEyeSlash, faAngleDown)

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

const app = createApp(App)
app.config.globalProperties.$toast = toastr
app.config.globalProperties.$router = router
app.config.globalProperties.$apiService = apiService

const existing = authHeader()
const user = JSON.parse(localStorage.getItem('user'))
setZohoUser(user);

/**
 * TODO (Daniel): Review as this may lead to future bugs
 *    existing would always be a valid object that may be empty without keys so not so sure of the validity of it's use
 *    here
 * */
if (existing && user) {
  store.dispatch('setAuthentication', true)
  setZohoUser(user);
  store.dispatch('setUser', user)
}

RemoteConfigService.initialize()
  .then(() => {
    FeatureFlagService.setFeatureFlagConfigData();
  }).then(() => {
    if (existing && existing.Authorization) {
      const user_token = localStorage.getItem('user_token')
      authorize(user_token);
      store.dispatch('ping/getAllPingMessages');
    }
  })
  .finally(() => {
    app.use(router)
      .use(store)
      .component('font-awesome-icon', FontAwesomeIcon)
      .mount('#app')
  })
export default app
