<template>
    <p>
        {{title}}
        <span class="badge" style="margin-left: 6px;">{{number_of_trips}}</span>
    </p>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: String,
    number_of_trips: Number
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
    .badge{
        width: 28px;
        height: 24px;
        left: 135px;
        top: 507px;
        background: #FFFFFF;
        border: 0.6px solid #0DAC5C;
        border-radius: 90px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        align-items: center;
        color: #0DAC5C;
    }
    p{
        /* Body Text/Standard - 16px/Medium */

        font-family: 'Heebo';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* Shuttlers Neutrals/900 */
        color: var(--shuttlers-nuetral-900);
        margin-bottom: 8px;
        margin-top: 32px;
    }
</style>
