<template>
    <div class="flex-row passenger-item-container">
        <img class="passenger-img" :src="passenger.user.avatar || user_placeholder"/>
        <div class="passenger-trip-info">
            <div class="flex-row-space-between">
                <div>
                    <p class="passenger-name">{{passenger.user.fname}} {{passenger.user.lname}}</p>
                    <div>
                        <img :src="ticket"/>
                        <span class="ticket-no">&nbsp;  {{passenger.bus_pass}}</span>
                    </div>
                </div>

                <div class=" flex-col right-align">
                    <p :class="isActiveTrip ? hideBlock : showBlock" class="drop-off right-align">
                        Drop off
                    </p>
                    <p
                        :class="isActiveTrip ? hideBlock
                        : showBlock"
                        class="passenger-destination"
                    >
                        {{passenger.bus_stop}}
                    </p>

                    <!-- <CicoButton
                        :class="passenger.isPickedUp ? showBlock : hideBlock"
                        type="dropoff"
                    /> -->

                    <!-- <CicoTag
                        :class="passenger.isDroppedOff ? showBlock : hideBlock"
                        type="droppedoff"
                    /> -->
                </div>

            </div>

            <div :class="isActiveTrip ? showBlock :hideBlock" style="margin-top: 16px;">
                <CicoButton :class="passenger.isPickedUp || passenger.isNoShow || passenger.isDroppedOff ? hideBlock : showBlock" style="margin-right: 16px;" type="pickup"/>
                <CicoButton :class="passenger.isPickedUp || passenger.isNoShow || passenger.isDroppedOff  ? hideBlock : showBlock" type="noshow"/>

                <CicoTag :class="passenger.isPickedUp ? showBlock :hideBlock" type="pickedup"/>
                <CicoTag :class="passenger.isNoShow ? showBlock :hideBlock" type="noshow-c"/>
            </div>

        </div>
    </div>
</template>

<script>
import user_placeholder from '../assets/icons/user-placeholder.svg'
import ticket from '../assets/icons/ticket.svg'
import CicoButton from './CicoButton.vue'
import CicoTag from './CicoTag.vue'
export default {
  name: 'PassengerItem',
  props: {
    passenger: Object
  },
  data () {
    return {
      user_placeholder: user_placeholder,
      ticket: ticket,
      isActiveTrip: false,
      showBlock: 'showBlock',
      hideBlock: 'hideBlock'
    }
  },
  methods: {
    pickUpPassenger () {
      const userIndex = this.passenger.id
      console.log(this.passengers)
    },
    noShowPassenger (user_id) {
      const userIndex = this.passengers.findIndex(user => user.id === user_id)
      this.passengers[userIndex].isNoShow = true
    },
    dropOffPassenger (user_id) {
      const userIndex = this.passengers.findIndex(user => user.id === user_id)
      this.passengers[userIndex].isDroppedOff = true
    }
  },
  components: { CicoButton, CicoButton, CicoTag, CicoTag, CicoTag }
}
</script>

<style>
    .showBlock{
        display: inline-block;
    }
    .hideBlock{
        display: none;
    }
    .passenger-img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #DFE0DF;
    }
    .passenger-item-container{
        margin-bottom: 30px;
    }
    .passenger-destination{
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 16px;
        color: #595E5C;
        margin-bottom: 0px;
    }
    .drop-off{
        font-style: normal;
        font-weight: 400;
        font-size: 0.69rempx;
        line-height: 14px;
        color: #0DAC5C;

    }
    .ticket-no{
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #595E5C;
    }
    .passenger-name{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;
        color: #101211;
        margin-bottom: 0px;
    }
    .passenger-trip-info{
        margin-left: 8px ;
        width: 100%;
    }
    .right-align{
        text-align: right;
    }
    .passenger-img{
        width: 40px;
        height: 40px;
    }
</style>
