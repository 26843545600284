<template>
  <PageTitle text="Profile" />
  <div v-if="getUser">
    <img class="profile-img" :src="getUser.avatar || user_placeholder" />
    <div class="captain-badge start-alert">Bus captain</div>
    <div>
      <form>
        <div class="form-group row">
          <div class="col-6">
            <label for="inputEmail4" class="form-label">First name</label>
            <input
              :value="getUser.fname"
              type="email"
              class="form-control"
              id="inputFirstName"
              readonly
            />
          </div>
          <div class="col-6">
            <label for="inputPassword4" class="form-label">Last name</label>
            <input
              :value="getUser.lname"
              type="text"
              class="form-control"
              id="inputLastName"
              readonly
            />
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input
            :value="getUser.email"
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="examplePhone">Phone number</label>
          <input
            :value="getUser.phone"
            type="tel"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="08012121212"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="examplePhone">Your city</label>
          <input
            :value="getUser.city"
            type="text"
            class="form-control"
            id="exampleInputPassword1"
            placeholder="lagos"
            readonly
          />
        </div>
        <div></div>
      </form>
    </div>

    <p @click="LogOutUser" class="logout">
      <a>
        <img :src="logout_icon" />
        Logout
      </a>
    </p>
  </div>

  <div v-else class="spinner-border m-5" role="status">
    <span class="sr-only"></span>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import router from "../router";
import logout_icon from "../assets/icons/logout.svg";
import PageTitle from "@/components/PageTitle.vue";
import { mapGetters, mapActions } from "vuex";
import user_placeholder from "../assets/icons/user-placeholder.svg";

export default {
  name: "AccountPage",
  computed: mapGetters(["getUser"]),
  data() {
    return {
      form: {
        email: "",
        phone_number: "",
        city: "",
        first_name: "",
        last_name: "",
        password: "",
      },
      logout_icon: logout_icon,
      user_placeholder: user_placeholder,
    };
  },
  methods: {
    ...mapActions(["LogOut", "resetStates"]),
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.password = "";
    },
    redirectToApphome() {
      router.push({ path: "/" });
    },
    LogOutUser() {
      this.redirectToApphome();
      this.LogOut();
      window.$zoho.salesiq.reset();
      this.resetStates();
    },
  },
  components: { Header, PageTitle },
};
</script>

<style>
.captain-badge {
  width: 96px;
  height: 28px;
  margin: 24px auto 32px auto;
  border-radius: 24px;
  text-align: center;
  padding: 2px 12px;
  font-size: 0.75rem;
  line-height: 20px;
}
.profile-img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 1.4px solid #dfe0df;
  display: block;
  margin: 0 auto;
  margin-top: 32px;
}
.logout a,
logout a:hover {
  color: #e13d45;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.form-description {
  /* Text/Subtext */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  /* Gray/Gray 4 */
  /* color: #575A65; */
  color: var(--form-desctiption-color);
}
.form-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
}
label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.form-group {
  margin-bottom: 24px;
}

.form-control {
  height: 56px;
  width: 100%;
  top: 290px;
  border-radius: 8px;
  margin-top: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(126, 239, 104, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.005) inset,
    0 0 4px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}

button {
  height: 56px;
  max-width: 100%;
  left: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
}

.black-button {
  background-color: var(--black-btn-bg-color);
  color: #ffffff;
  border-radius: 8px;
}

.green-button {
  background-color: var(--green-btn-bg-color);
  color: #000005;
  border-radius: 8px;
}
.white-button {
  background-color: #ffffff;
  color: #000005;
  border-radius: 8px;
  border: 1px solid black;
}
.logout {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: red;
  margin-top: 32px;
}

.logout a {
  text-decoration: none;
  color: red;
  font-weight: normal;
}
</style>
