'use strict'

import axios from 'axios'

class UnAuthorizedAccessError {
  constructor (e) {
    this.e = e
  }
}

const config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60 * 1000 // Timeout
}

const axiosInstance = axios.create(config)

export const setHeader = function (key, value) {
  axiosInstance.defaults.headers.common[key] = value
}

export const deleteHeader = function (key) {
  delete axiosInstance.defaults.headers.common[key]
}

export const authorize = function (token) {
  if (token) {
    setHeader('Authorization', `Bearer ${token}`);
  }

  axiosInstance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      if (error && error.response && error.response.status == 401) {
        return Promise.reject(new UnAuthorizedAccessError(error))
      }

      // Do something with response error
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error && error.response) {
        if (error.response.status == 401) {
          localStorage.clear()

          deleteHeader('Authorization')

          window.location.href = '/'
        }
      }
      return Promise.reject(error)
    }
  )
}

const apiService = axiosInstance

export default apiService
