<template>
    <div :key="trip.id" v-for="trip in upcoming_trips">
        <span  v-if="trip.trip_id"></span>
        <ActiveTripCard v-else :active_trip="trip"/>
    </div>

</template>

<script>
import ActiveTripCard from './ActiveTripCard.vue'
export default {
  name: 'UpcomingTrips',
  props: {
    upcoming_trips: Array
  },
  components: { ActiveTripCard }
}
</script>
