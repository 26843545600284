<template>
    <page-title-emit @previous="$emit('previous')" :text="`Payment`"/>
    <div class="booking-notice">
        <div class="flex-row align-start">
            <p class="message-large">Who will be making payment for this trip?</p>
        </div>
    </div>

    <div :class="getBookingUserDetails.guest_ref ? 'hideOption' : ''" class="main-balance wallet-item personal_wallet" @click="selectPassenger">
        <div class="flex-row-space-between">
            <div class="flex-row">
                <img class="wallet-list-icon" src="@/assets/icons/select-passenger.svg"/>
                <div>
                    <div class="wallet_type">Passenger</div>
                </div>
            </div>
            <img v-if="getPayingUser === 'passenger'" src='@/assets/icons/selected-wallet.svg'/>
            <img v-else src='@/assets/icons/not-selected-wallet.svg'/>
        </div>
    </div>

   <div class="credit_amount wallet-item company_wallet" @click="selectBusCaptain">
        <div class="flex-row-space-between">
            <div class="flex-row">
                <img class="wallet-list-icon" src="@/assets/icons/select-captain.svg"/>
                <div>
                    <div class="wallet_type">Bus Captain</div>
                </div>
            </div>
            <img v-if="getPayingUser === 'bus-captain'" src='@/assets/icons/selected-wallet.svg'/>
            <img v-else src='@/assets/icons/not-selected-wallet.svg'/>
        </div>
  </div>

   <button
        type="submit"
        class="booking-btn"
        @click="goToSelectWalletPage"
        :class="
                paid_by_selected ?
                'green-button': 'disabled-button'"
        :disabled="!paid_by_selected"
    >
        <span>
            <span>Proceed</span>
        </span>

    </button>
</template>

<script>
import Header from '@/components/Header.vue'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import PageTitleEmit from '@/components/PageTitleEmit.vue'
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import { ref } from 'vue'

export default {
  name: 'SelectWalletPage',
  emits: [
    'correct-otp-collected',
    'user-info-collected',
    'previous',
    'paying-wallet-selected',
    'wallet-type-selected',
    'guest-info-collected',
    'user-is-guest',
    'pickup-dropoff-selected'
  ],
  setup () {
    const isOpen = ref(false)

    return { isOpen }
  },
  data () {
    return {
      paid_by_selected: false,
      hideOption: 'hideOption'
    }
  },
  computed: {
    ...mapGetters([
      'getPayingUser',
      'getUser',
      'getBookingUserDetails'
    ])
  },
  methods: {
    ...mapActions(['setPayingUser', 'setPayingWallet']),
    selectBusCaptain () {
      this.paid_by_selected = true
      this.setPayingUser('bus-captain')
      this.setPayingWallet(this.getUser.wallet)
    },
    selectPassenger () {
      this.paid_by_selected = true
      this.setPayingUser('passenger')
      this.setPayingWallet(this.getBookingUserDetails.wallet)
    },
    goToSelectWalletPage () {
      this.$emit('paying-wallet-selected')
    }
  },
  components: {
    Header,
    Modal,
    PageTitleEmit
  }
}
</script>

<style scoped>
    .hideOption{
        display: none;
    }
</style>
