<template>
    <div>
        <img  @click="$emit('previous', 1)" class="back-btn" :src="back"/>
        <span>{{text}}</span>
    </div>
</template>

<script>
import back_btn from '../assets/icons/back.svg'
import router from '../router'
export default {
  name: 'PageTitleEmit',
  emits: ['previous'],
  props: {
    text: String
  },
  data () {
    return {
      back: back_btn
    }
  },
  methods: {
    goBack () {
      this.$emit('previous')
    }
  }
}
</script>

<style scoped>
    .back-btn{
        margin-right: auto;
        z-index: 100;
    }
    div{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #131615;
        margin: 12 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #EFF2F7;
        padding-bottom: 6px;

    }
    span{
        position: absolute;
        top: 30px;
        left: 0;
        width: 100%;
        text-align: center;
    }
</style>
