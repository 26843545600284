<template>
    <div class="cap-alert-container flex-row">
        <div>
            <p class="cap-alert-msg">Welcome to Shuttlers’ Bus Marshal App</p>
            <p class="cap-alert-decription">Monitoring compliance level of users and drivers.</p>
        </div>
        <img @click="goToProfilePage()" class="user_img" v-bind:src="user_img ? user_img : image_placeholder"/>
    </div>
</template>

<script>
import image_placeholder from '../assets/icons/user-placeholder.svg'
import router from '@/router'
export default {
  name: 'CapAlert',
  props: {
    user_img: String
  },
  data () {
    return {
      image_placeholder: image_placeholder
    }
  },
  methods: {
    goToProfilePage () {
      router.push('/profile')
    }
  }
}
</script>

<style scoped>
    .user_img{
        width: 32px;
        height: 32px;
        border-radius:50% ;
    }
    .cap-alert-container{
        background-color: #101211;
        border-radius: 8px;
        padding: 20px 16px;
        margin-bottom: 32px;
        font-family: var(--font-inter);
    }
    .cap-alert-msg{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        /* or 130% */
        /* Gray/White */
        color: #FFFFFF;
    }
    .cap-alert-decription{
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        /* or 138% */

        /* Shuttlers Neutrals/400 */

        color: #ACAFAE;
    }
</style>
