<template>
    <div>
        <img class="logo" :src="logo"/>
    </div>
</template>

<script>
import shuttlers_icon from '../assets/icons/shuttlers-logo.svg'
export default {
  name: 'Header',
  data () {
    return {
      logo: shuttlers_icon
    }
  }
}
</script>

<style scoped>
    .logo{
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 34px;
    }
</style>
