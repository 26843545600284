// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app'
import { fetchAndActivate, getRemoteConfig, getValue } from '@firebase/remote-config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

class FirebaseRemoteConfig {
  firebaseConfig = {
    apiKey: process.env.VUE_APP_RC_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_RC_FIREBASE_PROJECT_ID,
    appId: process.env.VUE_APP_RC_FIREBASE_APP_ID
  };

  app = null;

  async initialize () {
    const MINUTES_UNIT = 60
    try {
      this.app = initializeApp(this.firebaseConfig, 'remote-config')
      this.remoteConfig = getRemoteConfig(this.app)
      this.remoteConfig.settings.minimumFetchIntervalMillis = 1000 * (5 * MINUTES_UNIT) // 5 minutes
      await fetchAndActivate(this.remoteConfig)
    } catch (e) {
      console.error(e)
      console.info('Failed to fetch feature flags')
    }
  }

  getConfigurationValue (key) {
    const value = getValue(this.remoteConfig, key).asString()
    return value && value?.length ? JSON.parse(value) : null
  }
}

const RemoteConfigService = new FirebaseRemoteConfig()

export default RemoteConfigService
