<template>
  <div class="passengers-wrapper">
    <div class="passengers" v-if="hasPassengers">
      <div v-for="(users, bus_stop, index) in passengers" :key="index">
        <div class="drop-off-page" v-for="passenger in users" :key="passenger?.id">
          <div v-if="passenger?.routeSchedules[0]?.check_in_status ==='picked-up' || passenger?.routeSchedules[0]?.check_in_status ==='dropped-off'" class="flex-row passenger-item-container">
            <img class="passenger-img" :src="passenger?.user?.avatar || user_placeholder"/>
            <div class="passenger-trip-info">
              <div class="flex-row-space-between align-items-center">
                <div>
                  <p class="passenger-name">{{passenger?.user?.fname}} {{passenger?.user?.lname}}</p>
                  <div>
                    <img :src="ticket"/>
                    <span class="ticket-no">&nbsp;  {{passenger?.bus_pass}}</span>
                  </div>
                </div>

                <div class=" flex-col right-align">

                  <CicoButton
                      @click="markUserAsCheckedOut(
                                        passenger.routeSchedules[0].id
                                    )"
                      :class="passenger?.routeSchedules[0]?.check_in_status === 'dropped-off' ? hideBlock : showBlock"
                      type="dropoff"
                      :loading="button_loading"
                      :passenger_id="passenger?.routeSchedules[0]?.id"
                      :button_to_load = "processing_id"
                      :active_type="selected_active_type"
                  />

                  <CicoTag
                      :class="passenger?.routeSchedules[0]?.check_in_status === 'dropped-off' ? showBlock : hideBlock"
                      type="droppedoff"
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="d-flex justify-content-center">
        <div role="status" style="text-align: center;">
          <img class="no-trips-icon" src="@/assets/icons/no-trips-icon.svg"/>
          <p class="no-trips">No passenger dropped off</p>
          <p class="no-trips-description">
            Passengers dropped off will show up here
          </p>
        </div>
      </div>
    </template>
    <div class="btn-anchor">
      <button @click="redirectToApphome()" type="submit" class="green-button">Back to Home</button>
    </div>
  </div>
</template>

<script>
import shuttlers_location from '../assets/icons/shuttlers-location.svg'
import user_placeholder from '../assets/icons/user-placeholder.svg'
import PassengerItem from './PassengerItem.vue'
import CicoButton from './CicoButton.vue'
import CicoTag from './CicoTag.vue'
import ticket from '../assets/icons/ticket.svg'
import router from '../router'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import authHeader from '@/services/auth-header'

export default {
  name: 'DropOffPage',
  props: {
    passengers: Object
  },
  data () {
    return {
      processingCheckingIn: false,
      shuttlers_location: shuttlers_location,
      showBlock: 'showBlock',
      hideBlock: 'hideBlock',
      ticket: ticket,
      user_placeholder: user_placeholder,
      button_loading: true,
      processing_id: -1,
      selected_active_type: ''
    }
  },
  computed: {
    hasPassengers () {
      return Object.keys(this.passengers)?.length
    }
  },
  components: { PassengerItem, CicoButton, CicoTag },
  methods: {
    ...mapActions(['checkOutUser', 'setPassengers']),
    redirectToApphome () {
      router.push({ path: '/home' })
    },

    async markUserAsCheckedOut (user_route_schedule_id) {
      try {
        this.processingCheckingIn = true

        this.processing_id = user_route_schedule_id

        this.selected_active_type = 'dropoff'

        const data = { user_route_schedule_id }

        const trip_id = parseInt(localStorage.getItem('next_active_trip_id'), 10)
        await axios.post(`/trips/${trip_id}/checkout`, data, { headers: authHeader() })

        const route_id = this.$route.params.routeId
        const trip_date = this.$route.params.tripDate
        const driver_id = this.$route.params.driverId
        await this.silentlyUpdatePassenger({ route_id, trip_date, driver_id })

        this.processing_id = -1
      } catch (error) {
        console.log(error)
        this.$toast.warning(error.response?.data.message)

        this.processingCheckingIn = false

        this.processing_id = -1
      }
    },

    async silentlyUpdatePassenger (payload) {
      try {
        const res = await axios.get(`/routes/${payload.route_id}/passengers?tripDate=${payload.trip_date}&driverId=${payload.driver_id}`, { headers: authHeader() })
        const groupedPassengers = res.data.groupBy(user => {
          return user.pickup.location
        })
        await this.setPassengers(groupedPassengers)
      } catch (error) {
        this.$toast.warning(error.response?.data.message)
      }
    }
  }
}
</script>

<style lang="scss" >

.passengers-wrapper {
  font-size: 16px;

  .passengers {
    height: 100%;
    overflow: auto;
    padding-bottom: 4.5rem;
  }

  .btn-anchor {
    position: absolute;
    bottom: 1rem;
    width: 100%;
  }
}
    .bottom-botton{
        position: absolute;
        bottom: 8px;
        max-width: 300px;
        display: block;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

    }
    .busstop-and-passengers{
        margin-bottom: 16px;
    }
    .space-left{
        margin-left: 8px;
    }
    .shuttlers-location{
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 3px;
    }
    .bus-stop{
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 28px;
        color: #101211;
        margin-bottom: 0px;
    }
    .passenger-per-bus-stop{
        font-style: normal;
        font-weight: 400;
        font-size: 0.8125rem;
        line-height: 20px;
        /* Shuttlers Neutrals/600 */

        color: #737876;
    }

</style>
