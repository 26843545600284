const z = (window).$zoho || {};
z.salesiq = z.salesiq || {
  widgetcode:
    'c07e251c2a47b76dac591b5f1d0db4f647a16c291972ee2b20fc5a84b04f901c63896b03e636218464625c889f3a089b',
  values: {},
  ready: function () { }
};
const d = document;
const s = d.createElement('script');
s.type = 'text/javascript';
s.id = 'zsiqscript';
s.defer = true;
s.src = 'https://salesiq.zoho.com/widget';
const t = d.getElementsByTagName('script')[0];
t.parentNode?.insertBefore(s, t);
window.$zoho = z

export function setZohoUser(user) {
  try {
    window?.$zoho?.salesiq?.visitor?.name(user?.fname + ' ' + user?.lname);
    window?.$zoho?.salesiq?.visitor?.email(user?.email);
    window?.$zoho?.salesiq?.visitor?.contactnumber(user?.phone);
  } catch (error) {
    console.log(error);
  }
}
