<template>
    <div v-if="step === 0">
        <BackHeader @click="goHome"/>
        <p class="form-title">Forgot Password</p>
        <p class="form-description">Not a problem! Enter your account's email address below</p>

        <div>
            <form @submit.prevent="submit" >
                <div class="form-group">
                    <input
                        @keyup="checkFormComplete"
                        v-model="form.email"
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter your email address"
                    >
                </div>
                <button
                    @click="resetPassword"
                    type="submit"
                    class="black-button"
                >
                    <span v-if="!loading">Proceed</span>
                    <span v-else class="spinner-border text-light" role="status"></span>
                </button>
            </form>
        </div>

    </div>
    <div v-if="step === 1">
        <BackHeader @click="goHome"/>
        <div class="content">
            <img class="mail-sent" src="@/assets/icons/mail-sent.svg"/>
            <p class="success-header">You've got a mail!</p>
            <p class="success-message">We sent you a link to <span class="bold">{{form.email}}</span>
                To reset your password, please go to your email and click the link.
            </p>
            <button @click="goHome" type="submit" class="green-button finish">Finish</button>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import BackHeader from '@/components/BackHeader.vue'
import router from '@/router'
import axios from 'axios'
import authHeader from '@/services/auth-header'
export default {
  name: 'PasswordResetPage',
  data () {
    return {
      step: 0,
      form: {
        email: '',
        type: 'user'
      },
      loading: false
    }
  },
  methods: {
    goHome () {
      router.push({ name: 'AppHome' })
    },
    async resetPassword () {
      try {
        this.loading = true
        const response = await axios.post('/password',
          this.form,
          { headers: authHeader() }
        )
        this.loading = false
        console.log(response)
        this.step = 1
      } catch (error) {
        this.loading = false
        this.$toast.warning(error.response?.data.message)
      }
    }
  },
  components: { Header, BackHeader }
}
</script>

<style scoped lang="scss">
    .mail-sent{
        margin: 70px auto 70px;
    }
    .content{
        text-align: center;
    }
    .success-header{
        color: $grey-black-1;
        font-size: $header-text-24;
        font-weight: $weight-700;
    }
    .success-message{
        color: $grey-4;
        font-size: $body-text-14;
    }

    .finish{
        margin-top: 40px ;
    }
    .bold{
        color: $grey-black-1;
        font-weight: $weight-500;
    }
</style>
