<template>
    <div class="flex-row-space-between">
        <span class="left">{{title}}</span>
        <span class="right">{{value}}</span>
    </div>
</template>

<script>
export default {
  name: 'BreakDown',
  props: {
    title: String,
    value: String
  }
}
</script>

<style scoped>
    div{
        margin-bottom: 16px;
    }
    .left{
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;

        /* Shuttlers Neutrals/600 */
        color: #737876;
    }

    .right {
        font-weight: 500;
        font-size: 0.875em;
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: right;

        /* Shuttlers Neutrals/900 */

        color: #101211;
    }
</style>
