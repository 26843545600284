<template>
    <div>
        <span class="loader" v-if="loading && button_to_load === passenger_id && active_type === type">

                <div class="spinner-border spinner-border-sm text-success" role="status">
                     <span class="sr-only"></span>
                </div>
        </span>
        <span class="main" v-else>
            {{ type==="pickup" ? "Pickup"
                : type==="noshow" ? "No show"
                : type ==="dropoff" ? "Drop off"
                : type ==="pickedup" ? "Picked up"
                : type ==="noshow-c" ? "No show"
                : "Dropped off"
            }}
        </span>
    </div>
</template>

<script>
export default {
  name: 'CICOButton',
  props: {
    type: String,
    active_type: String,
    loading: Boolean,
    passenger_id: Number,
    button_to_load: Number
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>
    span.main {
        gap: 10px;
        height: 28px;
        padding: 4px 12px;

        border: 1px solid #B9BCC8;
        border-radius: 50px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        color: #09090F;
    }
    span.loader {
        gap: 10px;
        height: 28px;
        padding: 5px 24px;

        border: 1px solid #B9BCC8;
        border-radius: 50px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        color: #09090F;
    }
</style>
