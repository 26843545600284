<template>
    <span>
        Exclusive
    </span>
</template>

<script>
export default {
  name: 'ExclusiveBadge',
  props: {
    date: String
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
    span{
        background: #7529ff ;
        color: #ffffff;
        padding: 6px 8px;
        border-radius: 13px;
        font-size: 0.625rem;
        margin-left: 8px;
    }
</style>
