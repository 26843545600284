<template>
  <div v-show="show" class="ping-wrapper">
    <div class="content-header">
      <img class="close" src="@/assets/icons/close_icon.svg" @click="closeView()">
      <h3 class="bus-stop-title">{{pageTitle}}</h3>
    </div>
    <div class="content-body">
      <h3 class="page-title">Notify your passengers</h3>
      <p class="page-description">Kindly send your current status to the passengers on this bus stop</p>
      <div class="ping-options" v-if="pingMessages.length">
        <div class="ping-message"
             role="button"
             :class="{'ping-message--pinging': data === selectedMessage && sendingPing}"
             v-for="data in pingMessages"
             :key="data.id" @click="sendPing(data)">
          <p class="ping-message__title">“{{data.message}}”</p>
          <div class="loader spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <p class="ping-message">No ping options available.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'PingView',
  props: {
    show: {type: Boolean, required: true},
    pageTitle: {type: String, required: true},
    passengers: {type: Array, required: true},
  },
  data() {
    return {
      sendingPing: false,
      selectedMessage: null
    }
  },
  computed: {
    ...mapGetters('ping', ['pingMessages']),
  },
  created() {
    if(!this.pingMessages?.length) {
      this.$store.dispatch('ping/getAllPingMessages');
    }
  },
  methods: {
    sendPing(message) {
      this.selectedMessage = message;
      const passengerData = this.passengers[0];
      const recipients = (this.passengers || []).filter((item => !!item.user?.device?.token)).map((item) => ({userId: item.user_id, deviceToken: item.user?.device?.token}));
      if(recipients.length) {
        const payload = {
          message: message.message,
          recipients,
          itineraryId: passengerData.itinerary_id,
          routeCode: passengerData.route?.route_code,
          routeId: passengerData.route_id,
          tripDate: passengerData.start_date,
          busStop: this.pageTitle,
        }
        this.sendingPing = true;
        this.$apiService.post(`ping/create`, payload)
          .then(() => {
            this.$toast.success('Ping notification sent successfully!');
            this.closeView();
          })
          .catch(() => {
            this.$toast.error('Failed to send ping notification');
          })
          .finally(() => {
            this.sendingPing = false;
            this.selectedMessage = null;
          });
      } else {
        this.$toast.info('Unable to send notification to users at this Bus Stop');
      }
    },
    closeView() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.ping-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.25rem;
  z-index: 999;
  background-color: #FFFFFF;

  .content-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    .close {
      max-width: 2rem;
      position: absolute;
      left: 0;
      cursor: pointer;
    }

    .bus-stop-title{
      color: $sh-neutral-900;
      font-weight: 500;
      text-align: center;
      font-size: 1rem;
      line-height: 1.75rem;
      margin-bottom: 0;
    }
  }

  .content-body {
    .page-title {
      color: $sh-neutral-900;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-bottom: 0.75rem;
    }

    .page-description {
      color: $sh-neutral-600;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .ping-options {
      display: flex;
      flex-direction: column;
    }

    .ping-message {
      padding: 1rem 1.25rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      border-radius: 8px;
      border: 1px solid transparent;
      background-color: $sh-neutral-50;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }

      &__title {
        margin-bottom: 0;
      }

      .loader {
        display: none;
      }

      &:hover, &:focus, &--pinging {
        border: 1px solid $sh-green-500;
      }
      &--pinging {
        margin-right: 2rem;

        .loader {
          position: absolute;
          right: 1rem;
          display: block;
          height: 1rem;
          width: 1rem;
          border-width: 2px;
        }
      }
    }
  }
}
</style>
