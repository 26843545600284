<template>
    <page-title-emit @previous="$emit('previous')" :text="`Booking Information`"/>
    <div class="booking-notice">
        <div class="flex-row align-start">
            <p class="message-large">Enter your booking information</p>
        </div>
    </div>

    <div class="personal_wallet">
        <div>
            <form>
                <div class="form-group row">
                    <div class="col-6">
                        <label for="inputEmail4" class="form-label">First name</label>
                        <input v-model="v$.form.fname.$model" type="text" class="form-control" id="inputFirstName" placeholder="Enter first name" required>
                    </div>
                    <div class="col-6">
                        <label for="inputPassword4" class="form-label">Last name</label>
                        <input v-model="v$.form.lname.$model" type="text" class="form-control" id="inputLastName" placeholder="Enter last name" required>
                    </div>

                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                        v-model="v$.form.email.$model"
                        type="email"
                        class="form-control"
                        placeholder="Enter email"
                        required
                    >
                    <small
                        v-if="form.email.length > 0 && !isEmailValid"
                        id="emailHelp"
                        class="form-text text-danger"
                    >Invaid Email</small>
                </div>

                <div class="form-group">
                    <label for="examplePhone">Phone number</label>
                    <input
                        v-model="v$.form.phone.$model"
                        type="tel"
                        maxlength=11
                        class="form-control"
                        placeholder="Enter phone number"
                        required
                    >
                    <small
                        v-if="this.form.phone.length > 0 && !isPhoneValid"
                        id="emailHelp" class="form-text text-danger"
                    >Invalid Phone number</small>
                </div>

            </form>
        </div>
        <div>
                     <button
                        type="submit"
                        class="form-btn"
                        @click="onSubmit"
                        :class="
                                form.fname &&
                                form.lname &&
                                form.email &&
                                form.phone ?
                                'green-button': 'disabled-button'"
                        :disabled="
                                !form.fname ||
                                !form.lname ||
                                !form.email ||
                                !form.phone
                        "
                    >
                        <span v-if="!loading">
                            <span>Proceed</span>
                        </span>
                        <span v-else class="spinner-border text-light" role="status">
                        </span>

                    </button>
                </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import { mapActions, mapGetters } from 'vuex'
import PageTitle from '@/components/PageTitle.vue'
import axios from 'axios'
import PageTitleEmit from '../PageTitleEmit.vue'
import authHeader from '@/services/auth-header'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'

export default {
  name: 'GuestDetails',
  emits: [
    'user-info-collected',
    'previous',
    'paying-wallet-selected',
    'wallet-type-selected',
    'guest-info-collected',
    'user-is-guest',
    'pickup-dropoff-selected'
  ],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        fname: '',
        lname: '',
        email: '',
        country_code: 'NG',
        phone: ''
      },
      payment_source_is_main: true,
      wallet_options: [],
      booked: false,
      loading: false,
      showPassword: false,
      startMinute: 10,
      time: 600,
      trip: {},
      isPhoneValid: false,
      isEmailValid: false,
      isFnameValid: false,
      isLnameValid: false
    }
  },
  validations () {
    return {
      form: {
        fname: { required },
        lname: { required },
        email: { required, email },
        phone: { required }
      }
    }
  },
  computed: {
    // ...mapGetters([]),
  },
  watch: {
    'form.phone' () {
      this.validatePhoneNumber()
    },
    'form.email' () {
      this.validateEmail()
    },
    'form.fname' () {
      this.validateFname()
    },
    'form.lname' () {
      this.validateLname()
    }
  },
  methods: {
    ...mapActions(['setBookingUserDetails']),
    validatePhoneNumber () {
      if (isValidPhoneNumber(this.form.phone, 'NG') && !this.v$.form.phone.$error) {
        this.isPhoneValid = true
      } else this.isPhoneValid = false
    },
    validateEmail () {
      if (!this.v$.form.email.$error) {
        this.isEmailValid = true
      } else this.isEmailValid = false
    },
    validateFname () {
      if (!this.v$.form.fname.$error) {
        this.isFnameValid = true
      } else this.isFnameValid = false
    },
    validateLname () {
      if (!this.v$.form.lname.$error) {
        this.isLnameValid = true
      } else this.isLnameValid = false
    },
    areAllInputsValid () {
      if (this.isEmailValid && this.isPhoneValid && this.isFnameValid && this.isLnameValid) {
        return true
      } else return false
    },
    async onSubmit () {
      if (!this.areAllInputsValid()) {
        this.$toast.warning('All fields must be completed correctly')
        return
      }
      this.loading = true
      try {
        const response = await axios.post(
          '/bus-captains/users',
          this.form,
          { headers: authHeader() }
        )
        this.loading = false
        this.setBookingUserDetails(response.data)
        this.$emit('guest-info-collected')
      } catch (error) {
        this.$toast.warning(error.response?.data.message)
        this.loading = false
      }
    },
    selectMainWallet (event) {
      this.form.payment_source = 'main_balance'
    },
    selectCompanyWallet (event) {
      this.form.payment_source = 'credit_balance'
    },
    setBookForUserDetails () {
      this.form.route_id = this.getClickedTrip?.route_id
      this.form.itinerary_id = this.getClickedTrip?.userRoute?.itinerary_id
      this.form.pickup_id = this.getClickedTrip?.userRoute?.pickup_id
      this.form.destination_id = this.getClickedTrip?.userRoute?.destination_id
      this.form.start_date = this.getClickedTrip?.userRoute?.start_date
      this.form.end_date = this.getClickedTrip?.userRoute?.end_date
      this.form.days_ids = [this.getClickedTrip?.day_id]
      this.form.meta = this.getClickedTrip?.userRoute?.meta
      this.form.luggage_quantity = 1
      this.form.payment_source = 'main_balance'
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    }
  },
  components: { Header, PageTitle, PageTitleEmit }
}
</script>

<style scoped>
    .form-btn{
        margin-top: 54px;
    }
</style>
