<template>
  <div>
    <span class="upper-title">AGREEMENT</span>
    <div class="page-title">Terms of Service</div>
    <hr />

    <p class="agreement-body">
      We know it’s tempting to skip these Terms of service, but it is important
      to establish what you can expect from Shuttlers, and what we expect from
      you as a Bus Marshal/Captain.
    </p>

    <div class="page-sub-title">Marshal's responsibilities</div>
    <div class="agreement-body">
      <div class="agreement-body-list">
        <ListDot />
        <p>Ensures driver starts and ends trips</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>
          Ensures that only booked users get on the bus by verifying manifest
          booking.
        </p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Ensures that the driver adheres to the 2 minute wait time.</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Confirm seat bookings on the shuttles</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Check -In user bookings and drop off users on the app</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Guide users to the appropriate shuttles.</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Provide Shuttle information to users where required.</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>
          Escalate all user complaints and enquires to the Shuttlers team /
          Shuttlers' stand.
        </p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Help users book the Shuttles where required.</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>Carry out periodic spot checks on assigned vehicle.</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>
          Provide Shuttle operations information (breakdowns, arrests,
          accidents, etc) to the operations team where required.
        </p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>
          Notify users through instant messaging when the shuttle has arrived at
          the pick up location before the users.
        </p>
      </div>
    </div>

    <div class="page-sub-title">Marshal Profile & Qualification</div>
    <div class="agreement-body">
      <div class="agreement-body-list">
        <ListDot />
        <p>
          Shuttlers Marshals are volunteers / independent contractors who are
          responsible for ensuring that only booked users get on the shuttle.
          They are neither staff nor contract staff of Shuttlers. The Marshal's
          role could be temporary, dependent on the duration he / she could be
          available. The minimum duration for a Marshal's voluntary role is 1
          month and a notice period of 1 week for replacement.
        </p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>
          The Shuttlers Marshal is a young male or female who may be a student,
          business person or employee of any organization who is willing to earn
          extra money as a side-gig.
        </p>
      </div>
    </div>

    <div class="page-sub-title">Marshals' Benefits</div>
    <div class="agreement-body">
      <div class="agreement-body-list">
        <ListDot />
        <p>The Marshal earns a flat fee for every seat/user checked in.</p>
      </div>
      <div class="agreement-body-list">
        <ListDot />
        <p>
          The Marshal earns a base fee which would cover for calls and/or data
          required.
        </p>
      </div>
    </div>

    <div class="flex-row-space-between bottom-floating-buttons">
      <ModalButton
        @click="rejectTerms"
        type="secondary"
        text="Decline"
        data-dismiss="modal"
      />
      <ModalButton
        @click="acceptTerms"
        type="positive"
        text="Accept"
        data-dismiss="modal"
      />
    </div>
  </div>

  <br />
  <br />
  <br />
  <br />
  <br />
</template>

<script>
import ModalButton from "@/components/ModalButton.vue";
import ListDot from "@/components/ListDot.vue";
import router from "../router";
import { mapActions } from "vuex";
export default {
  name: "TermsPage",
  data() {
    return {
      accepted: false,
    };
  },
  methods: {
    ...mapActions(["LogOut", "resetStates"]),
    setCookie(value) {
      document.cookie = `bc_terms_accepted=${value}`;
    },
    acceptTerms() {
      this.setCookie(true);
      router.push("/home");
    },
    rejectTerms() {
      this.setCookie(false);
      localStorage.clear();
      this.redirectToApphome();
      this.LogOut();
      window.$zoho.salesiq.reset();
      this.resetStates();
    },
    redirectToApphome() {
      router.push({ path: "/" });
    },
  },
  components: { ModalButton, ListDot },
};
</script>

<style scoped>
hr {
  margin: 8px 0px 8px 0px;
}
.bottom-floating-buttons {
  position: fixed;
  width: 360px;
  bottom: 0px;
  margin-bottom: 20px;
}
.upper-title {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 20px;
  color: #8d918f;
}

.page-title {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #101211;
  margin-bottom: 0px;
}

.page-sub-title {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  color: #101211;
  margin-top: 32px;
  margin-bottom: 8px;
}

.agreement-body {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  color: #575a65;
}

.agreement-body p {
  margin-left: 8px;
  margin-bottom: 4px;
  text-align: justify;
}

.agreement-body-list {
  display: flex;
  align-items: flex-start;
}
</style>
