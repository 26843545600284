<template>
    <Header/>
    <CapAlert :user_img="getUser.avatar || ''"/>
    <div v-if="areTripsLoading" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
    <div v-else>
        <div v-if="userTrips.length === 0" class="d-flex justify-content-center">
            <div role="status" style="text-align: center;">
                <img class="no-trips-icon" src="@/assets/icons/no-trips-icon.svg"/>
                <p class="no-trips">No trips to show...</p>
                <p class="no-trips-description">
                    You are either not a Bus Captain or your assigned route has no upcoming trips at this moment.
                </p>
            </div>
        </div>
        <div v-else>
            <SectionHeader v-if="findNextActiveTrip(userTrips)" title="Active trip"/>
            <SectionHeader v-else title="Your next trip"/>
            <ActiveTripCard v-if="findNextActiveTrip(userTrips)" :active_trip='findNextActiveTrip(userTrips)'/>
            <ActiveTripCard v-else :active_trip='userTrips[0]'/>

            <SectionHeader v-if="findNextActiveTrip(userTrips)" title="Upcoming Trips" :number_of_trips="userTrips.length-1"/>
            <SectionHeader v-else title="Upcoming Trips" :number_of_trips="userTrips.length"/>
            <UpcomingTrips :upcoming_trips="userTrips"/>
        </div>
    </div>

</template>

<script>
import CapAlert from '@/components/CapAlert.vue'
import SectionHeader from '../components/SectionHeader.vue'
import ActiveTripCard from '@/components/ActiveTripCard.vue'
import UpcomingTrips from '@/components/UpcomingTrips.vue'
import { mapGetters, mapActions } from 'vuex'
import Header from '@/components/Header.vue'
export default {
  name: 'AppHome',
  props: {
    // is_next_trip_active : false,
  },
  computed: {
    ...mapGetters(['userTrips', 'getTripStatus', 'getUser', 'areTripsLoading'])
  },
  created () {
    this.fetchUserTrips()
  },
  methods: {
    ...mapActions(['fetchUserTrips']),
    findNextActiveTrip (all_trips) {
      for (let index = 0; index < all_trips.length; index++) {
        const trip = all_trips[index]
        if (trip.trip_id) {
          return trip
        }
      }
    }
  },

  components: { CapAlert, SectionHeader, ActiveTripCard, UpcomingTrips, Header }
}
</script>

<style>
    .form-description{
        /* Text/Subtext */
        /* font-family: 'Heebo'; */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* Gray/Gray 4 */
        /* color: #575A65; */
        color: var(--form-desctiption-color)
    }
    .form-title{
        /* //styleName: Heading - Old/H5; */
        font-family: Heebo;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0px;
    }
    label {
        /* //styleName: Body Text/Medium - 14px/Regular; */
        font-family: Heebo;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }
    .form-group{
        margin-bottom: 24px;
    }
    .form-control, select{
        height: 56px;
        width: 100%;
        top: 290px;
        border-radius: 8px;
        margin-top: 6px;
    }
    button {
        height: 56px;
        width: 100%;
        left: 20px;
        /* //styleName: Alternates/Alt 3 (Bold); */
        font-family: Heebo;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 32px;
    }
    .black-button{
        background-color: var(--black-btn-bg-color);
        color: #ffffff;
        border-radius: 8px;
    }
    .forgot-password-link{
        text-align: center;
        /* //styleName: Body Text/Medium - 14px/Medium; */
        font-family: Heebo;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
    }
    .no-trips-icon{
        margin-top: 90px;
    }
    .no-trips{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #101211;
        margin-top: 20px;
    }
    .no-trips-description{
        margin-top: 6px;
        margin-bottom: 60px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #8D918F;
    }
</style>
