import apiService from '@/services/api-service';

export default {
  namespaced: true,
  state: {
    pingMessages: [],
  },
  getters: {
    pingMessages: state => state.pingMessages,
  },
  actions: {
    setMessages ({ commit }, data) {
      commit('setPingMessages', data)
    },
    async getAllPingMessages({ commit }) {
      const result = await apiService.get('ping/messages')
        .then((response => {
          if(response && response.data?.data) {
            commit('setPingMessages', response.data?.data || [])
          }
          return response.data?.data || [];
        }));
      return result || [];
    }
  },
  mutations: {
    setPingMessages: (state, messages) => state.pingMessages = messages,
  }
}
