import { createRouter, createWebHistory } from 'vue-router'
import AppHome from '../pages/AppHome.vue'
import Login from '../pages/Login.vue'
import ProfilePage from '../pages/ProfilePage.vue'
import TripDetails from '../pages/TripDetails'
import ActiveTripPage from '../pages/ActiveTripPage'
import TermsPage from '../pages/TermsPage'
import PasswordResetPage from '../pages/PasswordResetPage'
import QRCodePage from '../pages/QRCodePage'
import BookForSomeoneWizard from '../pages/BookForSomeoneWizard'
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { guest: true }
  },
  {
    path: '/home',
    name: 'AppHome',
    component: AppHome,
    meta: { requiresAuth: true }
  },
  {
    path: '/trip-details/:tripId/:routeId/:tripDate/:driverId',
    name: 'TripDetail',
    component: TripDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/active-trip/:routeId/:tripDate/:driverId',
    name: 'ActiveTripPage',
    component: ActiveTripPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndCondition',
    component: TermsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/book',
    name: 'BookForSomeoneWizard',
    component: BookForSomeoneWizard,
    meta: { requiresAuth: true }
  },
  {
    path: '/qrcode/:routeId/:tripId/:tripDate/:routeCode/:isUpcoming/',
    name: 'QRCodePage',
    component: QRCodePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/password-reset',
    name: 'PasswordResetPage',
    component: PasswordResetPage,
    meta: { guest: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name == 'PasswordResetPage' && !store.getters.isAuthenticated) {
    next();
  } else if (to.name !== 'Login' && !store.getters.isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.name == 'Login' && from.name == 'ProfilePage') {
    next();
  } else if (to.name == 'Login' && store.getters.isAuthenticated) {
    next({ name: 'AppHome' });
  } else {
    next();
  }
})

export default router
