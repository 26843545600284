<template>
  <div v-if="step === 0">
    <Header />
    <p class="form-title">Welcome back, Marshal</p>
    <p class="form-description">Log into your bus marshal account</p>

    <div>
      <div class="login-tab">
        <span
          class="item-1"
          :class="login_type == 'email' ? 'active-login' : 'inactive-login'"
          @click="selectTab(1)"
        >
          Email Address
        </span>
        <span
          :class="login_type == 'email' ? 'inactive-login' : 'active-login'"
          @click="selectTab(2)"
        >
          Phone Number
        </span>
      </div>
      <div>
        <div class="form-group" v-if="login_type === 'email'">
          <label for="exampleInputEmail1">Email address</label>
          <input
            @keyup="checkFormComplete"
            v-model="v$.form.email.$model"
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
          <small
            v-if="form.email.length > 0 && !isEmailValid"
            id="emailHelp"
            class="form-text text-danger"
            >Invaid Email address</small
          >
        </div>

        <div class="form-group" v-if="login_type === 'phone'">
          <label>Phone number</label>
          <div
            id="phone_input_container"
            class="flex-row-center-center form-control"
          >
            <span class="input-group-prepend">
              <select class="custom-select" v-model="form.country">
                <option
                  :key="country.code"
                  v-for="country in countries"
                  v-bind:value="country"
                >
                  {{ countryCodeToEmoji(country.code) }}
                  {{ country.phone_code }}
                </option>
              </select>
            </span>
            <input
              @keyup="checkFormComplete"
              v-model="v$.form.phone.$model"
              type="tel"
              id="phone_number_input"
              aria-describedby="emailHelp"
              maxlength="11"
            />
          </div>
          <small
            v-if="form.phone.length > 0 && !isPhoneValid"
            id="emailHelp"
            class="form-text text-danger"
            >Invaid Phone number</small
          >
        </div>

        <button
          @click="goToPassword"
          type="submit"
          :class="
            (!isEmailValid && login_type === 'email') ||
            (!isPhoneValid && login_type !== 'email')
              ? 'disabled-button'
              : 'black-button'
          "
          :disabled="
            (!isEmailValid && login_type === 'email') ||
            (!isPhoneValid && login_type !== 'email')
          "
        >
          <span>Proceed</span>
        </button>
      </div>
    </div>
  </div>
  <div v-if="step === 1">
    <BackHeader text="" @click="goBack" />
    <p class="form-title">Enter password</p>
    <p class="form-description">
      You are about to login with
      <span v-if="form.email" style="font-weight: bold">{{ form.email }}</span>
      <span v-else style="font-weight: bold">{{ form.phone }}</span>
    </p>

    <form @submit.prevent="submit" class="push-top-40">
      <label for="exampleInputPassword1" class="flex-row-space-between">
        <span>Password</span>
        <span v-if="!sending_otp" class="second-label" @click="initiateOtp"
          >Login with OTP</span
        >
        <div v-else class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only"></span>
        </div>
      </label>
      <div class="w-100 position-relative mb-4">
        <input
          @keyup="checkFormComplete"
          v-model.trim="form.password"
          name="password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Enter Password"
          class="form-control password-input"
        />
        <font-awesome-icon
          @click="toggleShowPassword"
          v-if="showPassword"
          class="password-toggle"
          icon="fa-regular fa-eye"
        />
        <font-awesome-icon
          @click="toggleShowPassword"
          v-else
          class="password-toggle"
          icon="fa-regular fa-eye-slash"
        />
      </div>
      <button
        @click="onSubmit"
        type="submit"
        :class="!form.password ? 'disabled-button' : 'black-button'"
        :disabled="!form.password"
      >
        <span v-if="!loading">Proceed</span>
        <span v-else class="spinner-border text-light" role="status"></span>
      </button>
    </form>

    <p class="forgot-password-link">
      <router-link to="/password-reset">Forgot Password?</router-link>
    </p>
  </div>
  <div v-if="step === 2">
    <BackHeader text="" @click="goBack" />
    <p class="form-title">Confirm OTP</p>
    <p class="form-description">
      Please enter the 4-digit code sent to
      <span v-if="form.email" style="font-weight: bold">{{ form.email }}</span>
      <span v-if="form.phone" style="font-weight: bold">{{ form.phone }}</span>
    </p>

    <div>
      <form @submit.prevent="submit">
        <div class="flex-row-space-between otp-inputs">
          <input
            type="text"
            maxlength="1"
            class="form-control otp-form-control booking-option-input otp-input"
            id="one"
            aria-describedby="emailHelp"
            @keyup="enterCode($event, 'none', 'one', 'two')"
          />
          <input
            type="text"
            maxlength="1"
            class="form-control otp-form-control booking-option-input otp-input"
            id="two"
            aria-describedby="emailHelp"
            @keyup="enterCode($event, 'one', 'two', 'three')"
          />
          <input
            type="text"
            maxlength="1"
            class="form-control otp-form-control booking-option-input otp-input"
            id="three"
            aria-describedby="emailHelp"
            @keyup="enterCode($event, 'two', 'three', 'four')"
          />
          <input
            type="text"
            maxlength="1"
            class="form-control otp-form-control booking-option-input otp-input"
            id="four"
            aria-describedby="emailHelp"
            @keyup="enterCode($event, 'three', 'four', 'done')"
          />
        </div>

        <div class="resend-code-box">
          <p class="no-code">Didn't receive code?</p>
          <p class="no-code resend-code">
            <CountdownTimerVue
              value="120"
              :should-start="true"
              @on-timer-restart="initiateOtp"
            />
          </p>
        </div>
        <button
          @click="verifyOtp"
          type="submit"
          :class="
            isFormComplete
              ? 'black-button booking-btn'
              : 'disabled-button booking-btn'
          "
          :disabled="!isFormComplete"
        >
          <span v-if="!loading">
            <span>Proceed</span>
          </span>
          <span v-else class="spinner-border text-light" role="status"> </span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
// import router from '../router'
import { mapActions, mapGetters } from "vuex";
import countryCodeEmoji from "country-code-emoji";
import axios from "axios";
import authHeader from "@/services/auth-header";
import BackHeader from "@/components/BackHeader.vue";
import CountdownTimerVue from "@/components/CountdownTimer.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

export default {
  name: "Login",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      step: 0,
      form: {
        email: "",
        phone: "",
        password: "",
        otp: "",
        type: "user",
        country: "",
        otp_ref: "",
      },
      countries: [],
      isFormComplete: false,
      showError: false,
      loading: false,
      sending_otp: false,
      showPassword: false,
      login_type: "email",
      show_password_page: false,
      isEmailValid: false,
      isPhoneValid: false,
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        phone: { required },
      },
    };
  },
  computed: {
    ...mapGetters(["getAuthError"]),
  },
  created() {
    this.fetchCountries();
  },
  watch: {
    "form.email"() {
      this.validateEmail();
    },
    "form.phone"() {
      this.validatePhone();
    },
  },
  methods: {
    ...mapActions(["LogIn", "setAuthError", "LogInViaOtp"]),
    checkFormComplete() {
      this.error_message = null;
      this.isFormComplete = !!(this.form.email && this.form.password);
    },
    async fetchCountries() {
      const response = await axios.get("/countries");
      this.countries = response.data;
      this.form.country = this.countries[0];
    },
    countryCodeToEmoji(code) {
      return countryCodeEmoji(code);
    },
    validateEmail() {
      if (!this.v$.form.email.$error) {
        this.isEmailValid = true;
      } else this.isEmailValid = false;
    },
    validatePhone() {
      if (
        !this.v$.form.phone.$error &&
        isValidPhoneNumber(this.form.phone, "NG")
      ) {
        this.isPhoneValid = true;
      } else this.isPhoneValid = false;
    },
    async onSubmit(event) {
      event.preventDefault();
      let data = {};
      if (this.login_type === "email") {
        data = {
          email: this.form.email,
          password: this.form.password,
          type: "user",
        };
      } else {
        data = {
          phone: this.form.phone,
          password: this.form.password,
          country_code: this.form.country.code,
          type: "user",
        };
      }
      try {
        this.loading = true;
        this.showError = false;
        this.error_message = "";
        await this.LogIn(data);
        this.loading = false;
        if (this.getTermsCookieValue()) {
          await this.$router.push("home");
        } else {
          await this.$router.push("/terms-and-conditions");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.warning(error.response?.data.message);
      }
    },
    async initiateOtp() {
      this.sending_otp = true;
      let data = {};
      if (this.form.email) {
        data = {
          email: this.form.email,
          type: "user",
        };
      } else {
        data = {
          phone: this.form.phone,
          country_code: "NG",
          type: "user",
        };
      }
      try {
        const response = await axios.post("/otp-login", data, {
          headers: authHeader(),
        });
        this.form.otp_ref = response.data.reference_id;
        this.sending_otp = false;
        this.step = 2;
      } catch (error) {
        this.sending_otp = false;
        this.$toast.warning(error.response?.data.message);
      }
    },
    async verifyOtp() {
      try {
        this.loading = true;
        this.showError = false;
        this.error_message = "";
        const data = {
          otp_ref: this.form.otp_ref,
          otp_code: this.form.otp,
        };
        await this.LogInViaOtp(data);
        this.loading = false;
        if (this.getTermsCookieValue()) {
          this.$router.push("/home");
        } else this.$router.push("/terms-and-conditions");
      } catch (error) {
        this.form.otp = "";
        this.loading = false;
        this.$toast.warning(error.response?.data.message);
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.password = "";
    },
    redirectToApphome() {
      this.$router.push({ path: "/home" });
    },

    getTermsCookieValue() {
      const all_cookies = document.cookie
        .split(";")
        .map((cookie) => cookie.split("="))
        .reduce(
          (accumulator, [key, value]) => ({
            ...accumulator,
            [key.trim()]: decodeURIComponent(value),
          }),
          {}
        );
      return all_cookies.bc_terms_accepted === "true";
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    selectTab(tab_no) {
      this.form.email = "";
      this.form.phone = "";
      this.form.password = "";
      if (tab_no === 1) this.login_type = "email";
      else this.login_type = "phone";
    },
    goToPassword() {
      this.step = 1;
    },
    goBack() {
      this.step -= 1;
    },
    enterCode(event, previous, current, next) {
      if (event.key !== "Backspace") {
        if (next !== "done") {
          this.form.otp += document
            .getElementById(current)
            .value.replace(/[^0-9]/g, "");
          document.getElementById(next).focus();
        } else if (next === "done") {
          this.isFormComplete = true;
          this.form.otp += document
            .getElementById(current)
            .value.replace(/[^0-9]/g, "");
        }
      } else {
        this.isFormComplete = false;
        if (
          previous !== "none" &&
          document.getElementById(current).value === ""
        ) {
          document.getElementById("one").value = "";
          document.getElementById("two").value = "";
          document.getElementById("three").value = "";
          document.getElementById("four").value = "";
          document.getElementById("one").focus();
          this.form.otp = "";
        }
      }
    },
  },
  components: { Header, BackHeader, CountdownTimerVue },
};
</script>

<style lang="scss" scoped>
#phone_number_input {
  border: 0;
  height: 100%;
  width: 100%;
}
#phone_input_container {
  padding: 0px;
}
.custom-select {
  height: 90%;
  margin-left: 5px;
  border-width: 0px;
  border-right: 1px solid #ced4da;
}
.push-top-40 {
  margin-top: 40px;
}
.otp-form-control {
  width: 71px;
}
</style>
<style lang="scss">
$font-small: 13px;
$weight-500: 500;
$line-height-20: 20px;

$eight-14-padding: 8px 14px;

.active-login {
  color: $sh-green-700;
  background: $sh-green-50;
  line-height: $line-height-20;
  padding: $eight-14-padding;
}
.inactive-login {
  color: $sh-neutral-500;
  line-height: $line-height-20;
  padding: $eight-14-padding;
}
.item-1 {
  margin-right: 12px;
}
.login-tab {
  margin-top: 32px;
  margin-bottom: 40px;
}
.second-label {
  color: $sh-green-700;
  font-weight: $weight-500;
}
.password-toggle {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 35%;
}
.flex-push-right {
  display: flex;
  /* justify-content: flex-end; */
}
.show-password {
  margin-bottom: 4px;
}
.form-description {
  /* Text/Subtext */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  /* Gray/Gray 4 */
  /* color: #575A65; */
  color: var(--form-desctiption-color);
}
.form-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
}
label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.form-group {
  margin-bottom: 24px;
}

.form-control {
  height: 56px;
  width: 100%;
  top: 290px;
  border-radius: 8px;
  margin-top: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(126, 239, 104, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.005) inset,
    0 0 4px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}

button {
  height: 56px;
  max-width: 100%;
  left: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
}

.black-button {
  background-color: var(--black-btn-bg-color);
  color: #ffffff;
  border-radius: 8px;
}
.disabled-button {
  background-color: #d3dce6;
  color: #eff2f7;
  border-radius: 8px;
}

.green-button {
  background-color: var(--green-btn-bg-color);
  color: #000005;
  border-radius: 8px;
}

.forgot-password-link {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  margin-top: 56px;
}

.forgot-password-link a {
  text-decoration: none;
  color: #101211;
  font-weight: normal;
}

.otp-input {
  width: 71px;
  height: 71px;
  background: #f4f5f4;
  text-align: center;
}
.otp-inputs {
  margin-top: 32px;
}
.no-code {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: center;
  color: #6e717c;
  margin: 0;
}
.resend-code {
  margin: 0;
  color: #101211;
}
.count-down {
  color: #0dac5c;
}
.resend-code-box {
  margin-top: 46px;
}
</style>
