<template>
  <div v-if="!busStopsLoading">
    <page-title-emit @previous="$emit('previous')" :text="`Route`" />
    <div class="booking-notice">
      <div class="flex-row align-start">
        <img class="icon" src="../../assets/icons/info-circle.svg" />
        <p class="message">
          Select the pick up and drop off bus stop of the user.
        </p>
      </div>
    </div>

    <label class="flex-row-space-between">
      <span>Pick up bus stop</span>
    </label>
    <div class="w-100 position-relative mb-4">
      <select
        @click="selectPickUp"
        class="form-select drop-down"
        aria-label="Default select example"
      >
        <option disabled selected>
          {{ form.pickup.name || "Select pick up" }}
        </option>
      </select>
    </div>

    <label class="flex-row-space-between">
      <span>Drop off bus stop</span>
    </label>
    <div class="w-100 position-relative mb-4">
      <select
        @click="selectDropOff"
        class="form-select drop-down"
        aria-label="Default select example"
      >
        <option disabled selected>
          {{ form.dropoff.name || "Select drop off" }}
        </option>
      </select>
    </div>

    <button
      type="submit"
      class="booking-btn"
      @click="next"
      :class="
        form.pickup.id && form.dropoff.id ? 'green-button' : 'disabled-button'
      "
      :disabled="(!form.pickup.id && !form.dropoff.id) || errorFetchingPrice"
    >
      <span v-if="!processingExclusiveBooking">
        <span>Proceed</span>
      </span>
      <span v-else class="spinner-border text-light" role="status"> </span>
    </button>

    <!-- Modal -->
    <Modal :open="isOpen" @close="isOpen = !isOpen">
      <div style="text-align: center">
        <img class="bus-icon" src="@/assets/icons/bus-icon-sufficient.svg" />

        <p class="no-show-question">Booking Successful</p>

        <p class="no-show-description">
          You have successfully booked for
          <span style="font-weight: 500"
            >{{ getBookingUserDetails?.fname }}
            {{ getBookingUserDetails?.lname }}</span
          >
          on this trip.
        </p>
        <button
          @click="redirectToTripDetailsPage"
          type="submit"
          class="green-button to-trip-manifest"
        >
          <span>
            <span>View Trip Manifest</span>
          </span>
        </button>
      </div>
    </Modal>
  </div>

  <div v-else>
    <img
      @click="hideBusStops"
      class="move-right"
      src="@/assets/icons/cancel.svg"
    />
    <div id="phone_input_container" class="flex-row-aacjjc form-control">
      <span class="input-group-prepend">
        <img src="@/assets/icons/search.svg" />
      </span>
      <input
        type="text"
        id="phone_number_input"
        aria-describedby="emailHelp"
        maxlength="20"
        v-model.trim="searchQuery"
      />
    </div>

    <div v-if="pickUpInputSeleted">
      <div
        v-for="busStop in filteredBusStops"
        @click="selectThisPickUp(busStop)"
        class="pickup-item"
        :key="busStop.id"
      >
        {{ busStop.name }}
      </div>
    </div>

    <div v-else>
      <div
        v-for="busStop in filteredBusStops"
        @click="selectThisDropOff(busStop)"
        class="pickup-item"
        :key="busStop.id"
      >
        {{ busStop.name }}
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";
import PageTitleEmit from "@/components/PageTitleEmit.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";
import { ref } from "vue";
import authHeader from "@/services/auth-header";
import FeatureFlagService, { FEATURE_FLAGS } from "@/services/feature-flags";

export default {
  name: "SelectLocationPage",
  emits: [
    "correct-otp-collected",
    "user-info-collected",
    "previous",
    "paying-wallet-selected",
    "wallet-type-selected",
    "guest-info-collected",
    "user-is-guest",
    "pickup-dropoff-selected",
  ],
  watch: {
    busStopsSelection: {
      immediate: true,
      handler: async function (value) {
        const trip = this.getTripToBook;
        if (this.form.dropOffStop && this.form.pickupStop) {
          this.fare = await this.getTripFare(
            trip.itinerary,
            this.form.pickupStop,
            this.form.dropOffStop
          );
          if (this.errorFetchingPrice || this.fare === null) {
            this.$toast.error("Failed to retrieve price");
          } else {
            trip.itinerary.default_fare = this.fare;
            this.setTripToBook(trip);
          }
        }
      },
    },
  },
  setup() {
    const isOpen = ref(false);

    return { isOpen };
  },
  data() {
    return {
      paid_by_selected: false,
      hideOption: "hideOption",
      busStopsLoading: false,
      pickUpInputSeleted: false,
      dropOffInputSelected: false,
      form: {
        pickupStop: null,
        dropOffStop: null,
        pickup: {
          name: "",
          id: "",
        },
        dropoff: {
          name: "",
          id: "",
        },
      },
      searchQuery: "",
      mockBusStops: [],
      mockDropOff: [],
      // active_trip_id: "",
      itineraryPriceMap: new Map(),
      fetchingFare: false,
      errorFetchingPrice: false,
      fare: null,
      processingExclusiveBooking: false,
    };
  },
  created() {
    const trip = this.getTripToBook;
    this.fetchRouteBusStops(trip.route_id);
  },
  computed: {
    ...mapGetters([
      "getPayingUser",
      "getUser",
      "getBookingUserDetails",
      "getTripToBook",
    ]),
    isNewDRPEnabled() {
      return FeatureFlagService.isFlagEnabled(FEATURE_FLAGS.DRP_CLIENT);
    },
    busStopsSelection() {
      return `${this.form.pickupStop?.id}_${this.form.dropOffStop?.id}`;
    },
    filteredBusStops: function () {
      return this.mockBusStops.filter((busStop) =>
        busStop.name.toLowerCase().match(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions([
      "setPayingUser",
      "setPayingWallet",
      "setSomeonePickUpId",
      "setSomeoneDestinationId",
      "setTripToBook",
    ]),
    redirectToTripDetailsPage() {
      if (!this.getTripToBook.trip_id) {
        router.push({
          name: "TripDetail",
          params: {
            tripId: `${this.getTripToBook.id}`,
            routeId: `${this.getTripToBook.route_id}`,
            tripDate: `${this.getTripToBook.trip_date}`,
            driverId: `${this.getTripToBook.driver.id}`,
          },
        });
      } else {
        router.push({
          name: "ActiveTripPage",
          params: {
            routeId: `${this.getTripToBook.route_id}`,
            tripDate: `${this.getTripToBook.trip_date}`,
            driverId: `${this.getTripToBook.driver.id}`,
          },
        });
      }
    },
    async initiateExclusiveBooking() {
      this.processingExclusiveBooking = true;
      const payload = {
        user_id: this.getBookingUserDetails?.id,
        pickup_route_bus_stop_id: this.form.pickup.id,
        destination_route_bus_stop_id: this.form.dropoff.id,
      };
      // eslint-disable-next-line no-constant-condition
      try {
        await axios.post(
          `/trips/${
            this.getTripToBook.trip_id
              ? this.getTripToBook.trip_id
              : this.getTripToBook.id
          }/passengers?isUpcomingTrip=${!this.getTripToBook.trip_id}`,
          payload,
          { headers: authHeader() }
        );
        this.isOpen = !this.isOpen;
      } catch (error) {
        this.$toast.warning(error.response?.data.message);
      } finally {
        this.processingExclusiveBooking = false;
      }
    },
    convertXYtoLatLng(data) {
      return data ? { longitude: data.x, latitude: data.y } : null;
    },
    async getTripFare(itinerary, startStopData, endStopData) {
      const isNewDrpPricing = this.isNewDRPEnabled;
      let tripFare = isNewDrpPricing ? null : itinerary.default_fare;
      const key = `${itinerary.id}_${startStopData.id}_${endStopData.id}`;
      if (this.itineraryPriceMap.has(key)) {
        tripFare = this.itineraryPriceMap.get(key);
        return tripFare;
      }
      this.fetchingFare = true;
      this.errorFetchingPrice = false;
      const request = isNewDrpPricing
        ? this.$apiService.post("/drp/v1/pricing/pricing-calculator", {
          itineraryId: itinerary.id,
          coordinates: [
            this.convertXYtoLatLng(startStopData.geometry),
            this.convertXYtoLatLng(endStopData.geometry),
          ],
          startStopId: startStopData.id,
          endStopId: endStopData.id,
        })
        : this.$apiService.post(
            `/v1/routes/${itinerary.route_id}/price-calculation`,
            {
              pickup_id: startStopData.id,
              destination_id: endStopData.id,
            }
        );
      try {
        const res = await request;
        const priceData = res.data;
        if (isNewDrpPricing) {
          if (priceData.data) {
            this.itineraryPriceMap.set(
              `${priceData.data.itinerary_id}_${startStopData.id}_${endStopData.id}`,
              priceData.data.fare
            );
            tripFare = priceData.data.fare;
          }
        } else {
          if (priceData && priceData.length) {
            priceData.forEach((itineraryPrice) => {
              const { fare, itinerary_id } = itineraryPrice;
              if (itinerary_id === itinerary.id) {
                tripFare = fare;
              }
              this.itineraryPriceMap.set(
                `${itinerary_id}_${startStopData.id}_${endStopData.id}`,
                fare
              );
            });
          }
        }
      } catch (e) {
        this.errorFetchingPrice = true;
      } finally {
        this.fetchingFare = false;
      }
      return tripFare;
    },
    selectPickUp() {
      this.form.dropoff.name = "";
      this.form.dropoff.id = "";
      this.setSomeoneDestinationId("");
      this.pickUpInputSeleted = true;
      this.dropOffInputSelected = false;
      this.showBusStops();
    },

    selectDropOff() {
      this.dropOffInputSelected = true;
      this.pickUpInputSeleted = false;
      this.showBusStops();
    },
    showBusStops() {
      this.busStopsLoading = true;
    },
    hideBusStops() {
      this.busStopsLoading = false;
    },
    async next() {
      if (this.getTripToBook.route.is_exclusive === 1) {
        await this.initiateExclusiveBooking();
      } else {
        this.$emit("pickup-dropoff-selected");
      }
    },

    async fetchRouteBusStops(routeId) {
      try {
        const response = await axios.get(`/routes/${routeId}/busstops`, {
          headers: authHeader(),
        });
        this.mockBusStops = response.data.data;
      } catch (error) {
        this.$toast.warning(error.response?.data.message);
      }
    },
    setDropOffs(pickUpIndex) {
      this.mockDropOff = this.mockBusStops;
    },
    selectThisPickUp(pickUp) {
      this.form.pickupStop = pickUp;
      this.form.pickup.name = pickUp.name;
      this.form.pickup.id = pickUp.id;
      this.setSomeonePickUpId(pickUp.id);
      this.busStopsLoading = false;
      const pickUpIndex = this.mockBusStops.findIndex(
        (item) => item.id == pickUp.id
      );
      this.setDropOffs(pickUpIndex);
    },
    selectThisDropOff(dropOff) {
      this.form.dropOffStop = dropOff;
      this.form.dropoff.name = dropOff.name;
      this.form.dropoff.id = dropOff.id;
      this.setSomeoneDestinationId(dropOff.id);
      this.busStopsLoading = false;
    },
  },
  components: {
    Header,
    Modal,
    PageTitleEmit,
  },
};
</script>

<style scoped>
.drop-down {
  background-color: #f9fbfa;
  border: none;
}
.hideOption {
  display: none;
}

.move-right {
  display: block;
  margin-left: auto;
  margin-top: 30px;
}

#phone_number_input {
  border: 0;
  height: 100%;
  width: 100%;
}

.pickup-item {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101211;
  margin-top: 32px;
}
</style>
