<template>
  <div v-if="loading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div v-else>
    <page-title text="Trip Details" />
    <br />

    <div>
      <pickup-dropoff
        :from="trip_breakdown?.route.pickup"
        :to="trip_breakdown?.route.destination"
      />
      <br />

      <div class="tab-bg flex-row-space-between">
        <span
          @click="swapTabs(getSelectedTab.current_tab)"
          v-bind:class="getSelectedTab.selected0"
          class="tab-item tab1"
          >Trip Breakdown</span
        >
        <span
          @click="swapTabs(getSelectedTab.current_tab)"
          v-bind:class="getSelectedTab.selected1"
          class="tab-item tab2"
          >Manifest</span
        >
      </div>

      <div v-bind:class="getSelectedTab.display0" class="breakdown-tab">
        <SectionHeader title="Driver Details" />

        <template v-if="trip_breakdown">
          <DriverBusDetails :trip_breakdown="trip_breakdown" />
        </template>
        <template v-else>
          <div class="spinner-border m-2" role="status">
            <span class="sr-only"></span>
          </div>
        </template>

        <Breakdown2
          title="License Plate Number"
          :value="trip_breakdown?.vehicle.registration_number"
        />
        <Breakdown2
          title="Vehicle Type"
          :value="
            trip_breakdown?.vehicle.brand +
            ' ' +
            trip_breakdown?.vehicle.name +
            ' ' +
            trip_breakdown?.vehicle.type
          "
        />

        <hr />

        <SectionHeader title="Breakdown" />
        <BreakDown
          title="Date"
          :value="getStartDate(trip_breakdown?.start_date)"
        />
        <BreakDown
          title="Trip start time"
          :value="trip_breakdown?.itinerary.trip_time"
        />
        <BreakDown
          title="Estimated arrival time"
          :value="
            getETA(
              trip_breakdown?.itinerary?.trip_time,
              trip_breakdown?.route?.duration
            )
          "
        />
        <BreakDown
          title="Number of passengers"
          :value="trip_breakdown?.total_passengers.toString()"
        />
        <BreakDown
          title="Number of stops along routes"
          :value="trip_manifest_length.toString()"
        />
        <div class="btn-anchor flex flex-row">
          <button
            v-if="!trip_breakdown.route.is_exclusive === 1"
            type="submit"
            @click="viewQRCode"
            class="white-button shift-left"
          >
            <span>QR code &nbsp;&nbsp;</span>
            <img src="@/assets/icons/qrcode.svg" />
          </button>
          <button
            type="submit"
            @click="goToBookingWidget"
            class="space-left green-button"
          >
            Book for Someone
          </button>
        </div>
      </div>

      <div v-bind:class="getSelectedTab.display1" class="manifest-tab">
        <Manifest :trip_manifest="trip_manifest" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import PickupDropoff from "@/components/PickupDropoff.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import BreakDown from "@/components/BreakDown.vue";
import Breakdown2 from "../components/Breakdown2.vue";
import DriverBusDetails from "../components/DriverBusDetails.vue";
import CaptainTab from "@/components/CaptainTab.vue";
import Manifest from "@/components/Manifest.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import authHeader from "@/services/auth-header";
import "core-js/actual/array/group-by";
import router from "@/router";
import moment from "moment";

export default {
  components: {
    PageTitle,
    PickupDropoff,
    SectionHeader,
    BreakDown,
    Breakdown2,
    Breakdown2,
    SectionHeader,
    DriverBusDetails,
    CaptainTab,
    Manifest,
  },
  name: "TripDetails",
  computed: mapGetters([
    "getSelectedTab",
    "userTrips",
    "allPassengers",
    "getTripToBook",
  ]),
  props: {
    trip_id: Number,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.setPreviousRoute(from.name));
  },
  data() {
    return {
      trip_breakdown: {},
      trip_manifest: {},
      trip_manifest_length: 0,
      passenger_count: 0,
      error: "",
      prevRoute: null,
      loading: false,
    };
  },
  created() {
    this.getTripBreakDown();
    this.resetTabs();
    // this.getTripBookingData();
  },

  methods: {
    ...mapActions(["swapTabs", "resetTabs", "getTripPasengers"]),
    viewQRCode() {
      router.push({
        name: "QRCodePage",
        params: {
          routeId: this.getTripToBook?.route?.id,
          tripId: this.getTripToBook?.trip_id || this.getTripToBook?.id,
          tripDate: this.getTripToBook?.trip_date,
          // pickupBusStop: this.getTripToBook?.pickupBusStop8,
          routeCode: this.getTripToBook.route?.route_code,
          isUpcoming: !this.getTripToBook?.trip_id,
        },
      });
    },
    setPreviousRoute(route_name) {
      if (route_name === "BookForSomeoneWizard") {
        this.swapTabs(0);
      }
    },
    getETA(startTime, duration) {
      const endTime = moment(startTime, "HH:mm")
        .add(duration?.replace("mins", ""), "minutes")
        .format("LT");
      return endTime;
    },
    getStartDate(date) {
      return moment(date).format("MMM D[,] YYYY");
    },
    getTripBreakDown() {
      const route_day_id = this.$route.params.tripId;
      try {
        const index = this.userTrips.findIndex(
          (item) => item.id === +route_day_id
        );
        this.trip_breakdown = this.userTrips[index];
        const route_id = this.$route.params.routeId;
        const trip_date = this.$route.params.tripDate;
        const driver_id = this.$route.params.driverId;
        this.getTripPasengers(route_id, trip_date, driver_id);
        console.log(this.getTripPasengers(route_id, trip_date, driver_id));
      } catch (error) {
        this.$toast.warning(error.response?.data?.message);
      }
    },
    getTripPasengers(route_id, trip_date, driver_id) {
      this.loading = true;
      axios
        .get(
          `/routes/${route_id}/passengers?tripDate=${trip_date}&driverId=${driver_id}`,
          { headers: authHeader() }
        )
        .then((res) => {
          this.trip_manifest = res.data.groupBy((user) => {
            return user.pickup.location;
          });
          this.trip_manifest_length = Object.keys(this.trip_manifest).length;
          this.passenger_count = res.data.length;
        })
        .catch((err) => {
          this.$toast.warning(err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToBookingWidget() {
      router.push("/book");
    },
  },
};
</script>

<style scoped>
.show-block {
  display: block;
}
.hide-block {
  display: none;
}
hr {
  margin-top: 26px;
  margin-bottom: 32px;
  border: 1px solid #dfe0e3;
}

.selected-tab {
  background: #ffffff;
  /* Elevation 1 */

  box-shadow: 0px 2px 16px rgba(100, 100, 100, 0.1);
  border-radius: 6px;
  color: var(--shuttlers-green);
}
.tab-item {
  width: 49%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  /* Gray/White */
}
.tab-bg {
  height: 40px;
  /* Shuttlers Neutrals/100 */

  background: #f4f5f4;
  border-radius: 6px;
  align-items: center;
  padding-left: 3px;
  padding-left: 3px;
  margin-top: 24px;
  margin-bottom: 32px;
}
</style>
