<template>
    <page-title text='Book for someone'/>
    <div class="booking-notice">
        <div class="flex-row align-start">
            <img class="icon" src="../../assets/icons/info-circle.svg"/>
            <p class="message">
                Book a trip on behalf of users unable to book themselves by providing their email address or phone number.
            </p>
        </div>
    </div>

    <div class="title-rule flex-row">
        <span class="header-title" @click="next">PASSENGER DETAILS</span>
        <hr>
    </div>

    <div>
        <form @submit.prevent="submit" >
            <div class="form-group">
                <div class="flex-row-space-between">
                    <label>
                        {{email_selected ? "Email address" : "Phone number"}}
                    </label>
                    <label @click="swapBookingAlternative" id="alternative">
                        {{email_selected ? "Book with Phone number" : "Book with Email"}}
                    </label>
                </div>
                <input
                    @keyup="checkFormComplete"
                    v-model="v$.form.value.$model"
                    :type="email_selected ? 'email' : 'tel'"
                    :maxlength="!email_selected ? 11 : 100"
                    class="form-control booking-option-input"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    :placeholder="email_selected ? 'Enter email' : 'Enter phone number'"
                >
                <small
                    v-if="email_selected && form.value.length > 0 && v$.form.value.$error"
                    id="emailHelp"
                    class="form-text text-danger"
                >Invaid Email</small>
                <small
                    v-if="!email_selected && !isPhoneValid && form.value.length > 0"
                    id="emailHelp" class="form-text text-danger"
                >Invalid Phone number</small>
            </div>

            <button
                @click="onSubmit"
                type="submit"
                :class="form.value && isPhoneValid || form.value && !v$.form.value.$error ? 'black-button': 'disabled-button'"
                :disabled="email_selected && v$.form.value.$error || !email_selected && !isPhoneValid"
                class="booking-btn"
            >
                <span v-if="!loading">
                    <span>Proceed</span>
                </span>
                <span v-else class="spinner-border text-light" role="status">
                </span>
            </button>
        </form>

        <!-- Modal -->
        <div v-if="getBookingUserDetails">
            <Modal :open="isOpen" @close="isOpen = !isOpen">
                <div style="text-align: center;">
                    <div>
                            <img class="no-show-img" :src='getBookingUserDetails?.avatar || user_placeholder'/>
                            <p class="no-show-question">Book for {{getBookingUserDetails.fname}} {{getBookingUserDetails.lname}}</p>
                            <p class="no-show-description">
                                <span>You are about to book on behalf of {{getBookingUserDetails.fname}} {{getBookingUserDetails.lname}}, would you like to proceed?</span>
                            </p>

                            <div class="flex-row-space-between">
                                <ModalButton @click="isOpen = !isOpen" type="secondary" text="Cancel" data-dismiss="modal"/>
                                <ModalButton
                                    @click="next"
                                    type="green"
                                    text="Yes, book"
                                    data-dismiss="modal"
                                />
                            </div>
                        </div>
                </div>
            </Modal>
        </div>

         <!-- This modal is used when a user with an email of a company does not exist -->
        <div v-if="user_not_found">
            <Modal :open="user_not_found" @close="user_not_found = !user_not_found">
                <div style="text-align: center;">
                    <div>
                            <img v-if="user_not_found" class="no-show-img" :src='user_placeholder'/>
                            <p class="no-show-question">User not found</p>
                            <p class="no-show-description">
                                <span>This user does not exist within the company.</span>

                            </p>

                            <button
                                v-if="user_not_found"
                                @click="user_not_found = !user_not_found"
                                type="submit"
                                class="green-button to-trip-manifest"
                            >Okay</button>
                        </div>
                </div>
            </Modal>
        </div>
  </div>
</template>

<script>
import user_placeholder from '@/assets/icons/user-placeholder.svg'
import PageTitle from '@/components/PageTitle.vue'
import authHeader from '@/services/auth-header'
import { mapActions, mapGetters } from 'vuex'
import ModalButton from '../ModalButton.vue'
import Header from '@/components/Header.vue'
import router from '../../router'
import Modal from '../Modal.vue'
import axios from 'axios'
import { ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'

export default {
  name: 'PassengerDetails',
  setup () {
    const isOpen = ref(false)

    return {
      isOpen,
      v$: useVuelidate()
    }
  },
  props: {
    currentStep: Number
  },
  emits: [
    'correct-otp-collected',
    'user-info-collected',
    'previous',
    'paying-wallet-selected',
    'wallet-type-selected',
    'guest-info-collected',
    'user-is-guest',
    'pickup-dropoff-selected'
  ],
  data () {
    return {
      form: {
        value: ''
      },
      email_selected: true,
      loading: false,
      showPassword: false,
      is_email_login: true,
      user_placeholder: user_placeholder,
      user_not_found: false,
      isPhoneValid: false
    }
  },
  validations () {
    return {
      form: {
        value: { email }
      }
    }
  },
  computed: {
    ...mapGetters(['getBookingUserDetails', 'getUser'])
  },
  created () {
    this.resetSelectedPayingInfo()
  },
  watch: {
    'form.value' () {
      if (!this.email_selected) {
        this.validatePhoneNumber()
      }
    }
  },
  methods: {
    ...mapActions([
      'setBookingUserDetails',
      'setPayingUser',
      'setPayingWallet',
      'setUser'
    ]),
    next () {
      this.$emit('user-info-collected')
    },
    goToGuestPage () {
      this.$emit('user-is-guest')
    },
    resetSelectedPayingInfo () {
      this.setPayingUser('')
      this.setPayingWallet({})
    },
    swapBookingAlternative () {
      this.form.value = ''
      this.email_selected = !this.email_selected
    },
    validatePhoneNumber () {
      this.isPhoneValid = isValidPhoneNumber(
        this.form.value,
        'NG'
      )
    },
    async onSubmit (event) {
      event.preventDefault()
      let url
      this.loading = true
      if (this.email_selected) {
        url = `/bus-captains/users/single?email=${this.form.value}`
      } else {
        url = `/bus-captains/users/single?phone=${this.form.value}`
      }
      try {
        const response = await axios.get(url, { headers: authHeader() })
        if (response.data) {
          this.setBookingUserDetails(response.data) // set the user in state and move to the next page
          this.isOpen = !this.isOpen // Open the modal
          // this.next() // take user to select who is paying
        } else {
          // user is guest, proceed to collect more information
          this.goToGuestPage()
        }
        this.loading = false
        await this.getBusCaptainWallet()
      } catch (error) {
        this.user_not_found = true
        // this.$toast.warning(error.response?.data.message)
        this.loading = false
      }
    },
    async getBusCaptainWallet () {
      try {
        const url = `/bus-captains/users/single?email=${this.getUser.email}`
        const response = await axios.get(url, { headers: authHeader() })
        this.setUser(response.data)
      } catch (error) {
        this.$toast.warning('Unable to update bus captain wallet')
      }
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    }
  },
  components: { Header, PageTitle, ModalButton, Modal }
}
</script>

<style lang="scss">

    .align-start{
        align-items: flex-start;
    }
    .icon {
        margin-right: 8px;
    }
    .message{
        font-style: normal;
        font-weight: 400;
        font-size: $body-text-small;
        line-height: 20px;
        color: $sh-neutral-600;
    }
    .message-large{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #131615;
        margin: 24px 0px 24px 0px;
    }
    .booking-notice{
        margin-top: 16px;
    }
    hr{
        flex-grow: 1;
    }
    .title-rule{
        color: $sh-green-700;
        margin-top: 40px;
    }
    .header-title{
        margin-right: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8125rem;
    }
    #alternative{
        color: $sh-green-700;
        font-weight: 500;
    }
    .booking-option-input{
        border: none;
        background: $grey-11;
    }
    .booking-btn{
        margin-top: 56px;
    }
    .no-show-img{
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        border: 1.4px solid #DFE0DF;
    }

</style>
