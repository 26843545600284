<template>
    <div class="tab-bg flex-row-space-between">
        <span @click="chooseTab0()" v-bind:class="selected_tab.selected0" class="tab-item tab1">Trip Breakdown</span>
        <span @click="chooseTab1()" v-bind:class="selected_tab.selected1" class="tab-item tab2">Manifest</span>
    </div>
</template>

<script>
export default {
  name: 'CaptainTab',
  data () {
    return {
      selected_tab: {
        name: 'tab1',
        selected0: 'selected-tab',
        selected1: ''
      },
      tab: 0
    }
  },
  methods: {
    chooseTab0 () {
      this.tab = 0
      this.switchTab()
    },
    chooseTab1 () {
      this.tab = 1
      this.switchTab()
    },
    switchTab () {
      if (this.tab === 0) {
        this.selected_tab = {
          ...this.selected_tab,
          selected0: 'selected-tab',
          selected1: ' \'\' '
        }
      } else {
        this.selected_tab = {
          ...this.selected_tab,
          selected0: ' \'\' ',
          selected1: 'selected-tab'

        }
      }
    }
  }

}
</script>

<style scoped>
    .selected-tab{
        background: #FFFFFF;
        /* Elevation 1 */

        box-shadow: 0px 2px 16px rgba(100, 100, 100, 0.1);
        border-radius: 6px;
        color: var(--shuttlers-green);
    }
    .tab-item{
        width: 49%;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;

        /* Gray/White */
    }
    .tab-bg{
        height: 40px;
        /* Shuttlers Neutrals/100 */

        background: #F4F5F4;
        border-radius: 6px;
        align-items: center;
        padding-left: 3px ;
        padding-left: 3px ;
        margin-top: 24px;
        margin-bottom: 32px;
    }
</style>
