/**
 * TODO (Daniel): Review approach adopted with the use of this in api request calls, introduces some level of repitition
 *      Suggestion to adopt the use of axios interceptors to append auth header instead within a logged in context
 * */
export default function authHeader () {
  const user_token = localStorage.getItem('user_token')
  if (user_token) {
    return { Authorization: 'Bearer ' + user_token }
  } else {
    return {}
  }
}
