import axios from 'axios'
import authHeader from '@/services/auth-header'
import 'core-js/actual/array/group-by'

const state = {
  trips: [],
  trip_status: false,
  passengers: {},
  route_id: '',
  selected_tab: {
    selected0: 'selected-tab',
    selected1: '',
    display0: 'show-block',
    display1: 'hide-block',
    current_tab: 0
  },
  general_error: '',
  loading: true,
  clicked_button: undefined,
  loading_trips: false
}

const getters = {
  allPassengers: state => state.passengers,
  getSelectedTab: state => state.selected_tab,
  userTrips: state => state.trips,
  areTripsLoading: state => state.loading_trips,
  getTripStatus: state => state.trip_status,
  getGeneralError: state => state.general_error,
  getLoadingState: state => state.loading,
  getClickedButton: state => state.clicked_button

}

const actions = {
  async getNumberOfPassengers ({ commit }, route_id, start_date) {
    try {
      const response = await axios.get(`/routes/${route_id}/passengers`,
        {
          headers: authHeader(),
          params: { tripDate: start_date.slice(0, 11) }
        }
      )
      commit('setPassengers', response.data)
    } catch (error) {
      console.log('unable to get no. of passengers')
    }
  },
  async fetchUserTrips ({ commit }) {
    // get user info on load on the app
    try {
      commit('setTripsLoader', true)
      const user_id = localStorage.getItem('user_id')
      const response = await axios.get(`/bus-captains/users/${user_id}/trips`, { headers: authHeader() })
      const user_routes = response.data
      if (user_routes.length > 0) {
        user_routes.sort(function (trip_a, trip_b) {
          const first_trip_time = new Date(trip_a.trip_date + ' ' + trip_a.itinerary.trip_time)
          const second_trip_time = new Date(trip_b.trip_date + ' ' + trip_b.itinerary.trip_time)
          return first_trip_time - second_trip_time
        })
        localStorage.setItem('next_trip_driver_id', user_routes[0].driver.id)
        localStorage.setItem('active_route_id', user_routes[0].route_id)
        commit('setTrips', user_routes)
      }
      commit('setTripsLoader', false)
    } catch (error) {
      commit('setTripsLoader', false)
    }
  },
  getTripPasengers ({ commit }, payload) {
    commit('setLoadingState', true)
    axios.get(`/routes/${payload.route_id}/passengers?tripDate=${payload.trip_date}&driverId=${payload.driver_id}`, { headers: authHeader() })
      .then(res => {
        this.error = ''
        const response = res.data.groupBy(user => {
          return user.pickup.location
        })
        commit('setLoadingState', false)
        commit('setPassengers', response)
      }).catch(err => {
        commit('setLoadingState', false)
        this.general_error = 'unable to fetch passengers'
      })
  },
  setPassengers ({ commit }, groupedPassenggers) {
    commit('setPassengers', groupedPassenggers)
  },
  swapTabs ({ commit }, current_tab) {
    commit('changeTabs', current_tab)
  },
  resetTabs ({ commit }) {
    commit('resetTabs')
  },
  resetStates ({ commit }) {
    commit('resetStates')
  },
  setLoadingState ({ commit }, loading_state) {
    commit('setLoadingState', loading_state)
  },
  setClickedButton ({ commit }, button_id) {
    commit('setClickedButton', button_id)
  }
}

const mutations = {
  setClickedButton: (state, button_id) => state.button_id = button_id,
  setLoadingState: (state, loading_state) => state.loading = loading_state,
  setPassengers: (state, passengers) => state.passengers = passengers,
  setLoader: (state) => state.loading = !state.loading,
  checkInPassengerStatus: (state, payload) => {
    const passengerIndex = state.passengers[payload.bus_stop].findIndex(user => user.user_id === payload.user_id)
    state.passengers[payload.bus_stop][passengerIndex].routeSchedules[0].check_in_status = 'picked-up'
  },
  checkOutPassengerStatus: (state, payload) => {
    const passengerIndex = state.passengers[payload.bus_stop].findIndex(user => user.user_id === payload.user_id)
    state.passengers[payload.bus_stop][passengerIndex].routeSchedules[0].check_in_status = 'dropped-off'
  },
  noShowPassengerStatus: (state, payload) => {
    const passengerIndex = state.passengers[payload.bus_stop].findIndex(user => user.user_id === payload.user_id)
    state.passengers[payload.bus_stop][passengerIndex].routeSchedules[0].check_in_status = 'no-show'
  },
  setTripsLoader: (state, value) => state.loading_trips = value,
  setTrips: (state, trips) => state.trips = trips,
  setGeneralError: (state, error_message) => state.general_error = error_message,
  changeTabs (state, current_tab) {
    if (current_tab === 0) {
      state.selected_tab = {
        ...state.selected_tab,
        selected0: 'selected-tab',
        selected1: ' \'\' ',
        display0: 'show-block',
        display1: 'hide-block',
        current_tab: 1
      }
    } else {
      state.selected_tab = {
        ...state.selected_tab,
        selected0: ' \'\' ',
        selected1: 'selected-tab',
        display0: 'hide-block',
        display1: 'show-block',
        current_tab: 0
      }
    }
  },
  resetTabs (state) {
    state.selected_tab = {
      ...state.selected_tab,
      selected0: 'selected-tab',
      selected1: '\'\'',
      display0: 'show-block',
      display1: 'hide-block',
      current_tab: 0
    }
  },
  setRouteId (state, route_id) {
    state.route_id = route_id
  },
  resetStates (state) {
    state.trips = []
    state.trip_status = false
    state.passengers = {}
    state.route_id = ''
    state.selected_tab = {
      selected0: 'selected-tab',
      selected1: '\'\'',
      display0: 'show-block',
      display1: 'hide-block',
      current_tab: 0
    },
    state.general_error = ''
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
