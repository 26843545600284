<template>
    <span :src="date_icon">
        <img :src="date_icon"/>
        {{date}}
    </span>
</template>

<script>
import date_icon from '../assets/icons/calendar.svg'
export default {
  name: 'DateTripTag',
  props: {
    date: String
  },
  data () {
    return {
      date_icon: date_icon
    }
  }
}
</script>

<style scoped>
    span{
        background: #F4F5F4;
        padding: 6px 8px;
        border-radius: 13px;
        color:#444854;
        font-size: 62.5%;
        margin-right: 8px;
    }
</style>
