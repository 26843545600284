<template>
  <div>
    <div v-if="passengers">
      <div
        :class="{ hidden: showPingModal }"
        v-for="(users, bus_stop, index) in passengers"
        :key="index"
      >
        <div class="flex-row busstop-and-passengers">
          <div class="col d-flex">
            <img class="shuttlers-location" :src="shuttlers_location" />
            <div class="space-left">
              <p class="bus-stop">{{ bus_stop }}</p>
              <p class="passenger-per-bus-stop">
                Picking up {{ passengers[bus_stop].length }} passengers
              </p>
            </div>
          </div>

          <div
            class="col-auto d-flex align-items-center"
            v-if="users && users.length"
          >
            <button
              class="btn btn-sm ping-btn"
              @click="openPingModal(users, bus_stop)"
            >
              <img class="icon" src="@/assets/icons/msg_icon.svg" />Ping
            </button>
          </div>
        </div>

        <div v-for="passenger in users" :key="passenger?.id">
          <div class="flex-row passenger-item-container">
            <img
              class="passenger-img"
              :src="passenger?.user?.avatar || user_placeholder"
            />
            <div class="passenger-trip-info">
              <div class="flex-row-space-between">
                <div>
                  <p class="passenger-name">
                    {{ passenger?.user?.fname }} {{ passenger?.user?.lname }}
                  </p>
                  <div>
                    <img :src="ticket" />
                    <span class="ticket-no"
                      >&nbsp; {{ passenger?.bus_pass }}</span
                    >
                  </div>
                </div>
              </div>

              <div style="margin-top: 16px">
                <CicoButton
                  @click="
                    markUserAsCheckedIn(
                      passenger.user_id,
                      passenger.route_id,
                      passenger.pickup_id,
                      passenger.routeSchedules[0].id
                    )
                  "
                  :class="
                    passenger?.routeSchedules[0]?.check_in_status === 'pending'
                      ? showBlock
                      : hideBlock
                  "
                  style="margin-right: 16px"
                  type="pickup"
                  :passenger_id="passenger?.routeSchedules[0]?.id"
                  :loading="button_loading"
                  :button_to_load="processing_id"
                  :active_type="selected_active_type"
                />
                <CicoButton
                  data-toggle="modal"
                  :data-target="
                    '#' + 'exampleModal' + passenger?.routeSchedules[0]?.id
                  "
                  :class="
                    passenger?.routeSchedules[0]?.check_in_status === 'pending'
                      ? showBlock
                      : hideBlock
                  "
                  type="noshow"
                  :passenger_id="passenger?.routeSchedules[0]?.id"
                  :loading="button_loading"
                  :button_to_load="processing_id"
                  :active_type="selected_active_type"
                />

                <a
                  :href="'tel:' + passenger?.user?.phone"
                  class="phone"
                  :class="
                    passenger?.routeSchedules[0]?.check_in_status === 'pending'
                      ? showBlock
                      : hideBlock
                  "
                >
                  <img class="icon-44" src="@/assets/icons/phone.svg" />
                </a>

                <CicoTag
                  :class="
                    passenger?.routeSchedules[0]?.check_in_status ===
                      'picked-up' ||
                    passenger?.routeSchedules[0]?.check_in_status ===
                      'dropped-off'
                      ? showBlock
                      : hideBlock
                  "
                  type="pickedup"
                />
                <CicoTag
                  :class="
                    passenger?.routeSchedules[0]?.check_in_status === 'no-show'
                      ? showBlock
                      : hideBlock
                  "
                  type="noshow-c"
                />
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            :id="'exampleModal' + passenger?.routeSchedules[0]?.id"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <img
                    class="no-show-img"
                    :src="passenger?.user?.avatar || user_placeholder"
                  />
                  <p class="no-show-question">Mark as No Show?</p>
                  <p class="no-show-description">
                    Confirm that
                    <span style="font-weight: 500"
                      >{{ passenger?.user?.fname }}
                      {{ passenger?.user?.lname }}</span
                    >
                    is not at the bus stop or on the bus before taking this
                    action.
                  </p>
                  <div class="flex-row-space-between">
                    <ModalButton
                      type="secondary"
                      text="Cancel"
                      data-dismiss="modal"
                    />
                    <ModalButton
                      @click="markUserAsNoShow(passenger.routeSchedules[0].id)"
                      type="danger"
                      text="Mark as No Show"
                      data-dismiss="modal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ping-view
        v-if="pingModalData"
        :show="showPingModal"
        :page-title="pingModalData.busStop"
        :passengers="pingModalData.users"
        @close="closePingModal()"
      >
      </ping-view>
    </div>
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div class="btn-anchor flex flex-row">
      <button
        v-if="getTripToBook?.route?.is_exclusive === 1"
        type="submit"
        @click="viewQRCode"
        class="white-button shift-left"
      >
        <span>QR code &nbsp;&nbsp;</span>
        <img src="@/assets/icons/qrcode.svg" />
      </button>
      <button
        type="submit"
        @click="goToBookingWidget"
        class="space-left green-button"
      >
        Book for Someone
      </button>
    </div>
  </div>
</template>

<script>
import shuttlers_location from "../assets/icons/shuttlers-location.svg";
import user_placeholder from "../assets/icons/user-placeholder.svg";
import PassengerItem from "./PassengerItem.vue";
import CicoTag from "./CicoTag.vue";
import CicoButton from "./CicoButton.vue";
import ticket from "../assets/icons/ticket.svg";
import ModalButton from "./ModalButton.vue";

import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import axios from "axios";
import authHeader from "@/services/auth-header";
import "core-js/actual/array/group-by";
import PingView from "@/components/PingView";

export default {
  name: "CICOPage",
  props: {
    passengers: Object,
    loading: Boolean,
  },
  components: { PassengerItem, CicoTag, CicoButton, ModalButton, PingView },
  data() {
    return {
      processingCheckingIn: false,
      shuttlers_location: shuttlers_location,
      showBlock: "showBlock",
      hideBlock: "hideBlock",
      ticket: ticket,
      user_placeholder: user_placeholder,
      clicked_user: undefined,
      button_loading: true,
      processing_id: -1,
      selected_active_type: "",
      showPingModal: false,
      pingModalData: null,
    };
  },
  computed: mapGetters(["getGeneralError", "getTripToBook"]),
  methods: {
    viewQRCode() {
      router.push({
        name: "QRCodePage",
        params: {
          routeId: this.getTripToBook?.route?.id,
          tripId: this.getTripToBook?.trip_id || this.getTripToBook?.id,
          tripDate: this.getTripToBook?.trip_date,
          // pickupBusStop: this.getTripToBook?.pickupBusStop8,
          routeCode: this.getTripToBook.route?.route_code,
          isUpcoming: !this.getTripToBook?.trip_id,
        },
      });
    },
    openPingModal(users, busStop) {
      this.pingModalData = { users, busStop };
      this.showPingModal = true;
    },
    closePingModal() {
      this.showPingModal = false;
      this.pingModalData = null;
    },
    ...mapActions(["getTripPasengers", "setPassengers"]),
    goToBookingWidget() {
      router.push("/book");
    },
    async markUserAsCheckedIn(
      user_id,
      route_id,
      pickup_id,
      user_route_schedule_id
    ) {
      try {
        this.processingCheckingIn = true;

        this.processing_id = user_route_schedule_id;

        this.selected_active_type = "pickup";

        const data = {
          user_id,
          route_id,
          pickup_id,
          user_route_schedule_id,
          driver_id: localStorage.getItem("next_trip_driver_id"),
        };

        const trip_id = parseInt(
          localStorage.getItem("next_active_trip_id"),
          10
        );
        await axios.post(`/trips/${trip_id}/checkin`, data, {
          headers: authHeader(),
        });

        const trip_date = this.$route.params.tripDate;
        const driver_id = this.$route.params.driverId;
        await this.silentlyUpdatePassenger({ route_id, trip_date, driver_id });

        this.processingCheckingIn = false;

        this.processing_id = -1;
      } catch (error) {
        this.$toast.warning(error.response?.data.message);

        this.processingCheckingIn = false;

        this.processing_id = -1;
      }
    },
    async markUserAsNoShow(user_route_schedule_id) {
      try {
        this.processingCheckingIn = true;

        this.selected_active_type = "noshow";

        this.processing_id = user_route_schedule_id;

        const data = { user_route_schedule_id };

        const trip_id = parseInt(
          localStorage.getItem("next_active_trip_id"),
          10
        );
        await axios.post(`/trips/${trip_id}/no-show`, data, {
          headers: authHeader(),
        });

        const route_id = this.$route.params.routeId;
        const trip_date = this.$route.params.tripDate;
        const driver_id = this.$route.params.driverId;
        await this.silentlyUpdatePassenger({ route_id, trip_date, driver_id });

        this.processingCheckingIn = false;

        this.processing_id = -1;
      } catch (error) {
        this.$toast.warning(error.response?.data.message);

        this.processingCheckingIn = false;

        this.processing_id = -1;
      }
    },
    async silentlyUpdatePassenger(payload) {
      try {
        const res = await axios.get(
          `/routes/${payload.route_id}/passengers?tripDate=${payload.trip_date}&driverId=${payload.driver_id}`,
          { headers: authHeader() }
        );
        const groupedPassengers = res.data.groupBy((user) => {
          return user.pickup.location;
        });
        await this.setPassengers(groupedPassengers);
      } catch (error) {
        this.$toast.warning(error.response?.data.message);
      }
    },
    showError() {
      this.processing_id = -1;
      this.$toast.warning(this.getGeneralError);
    },
  },

  watch: {
    getGeneralError() {
      this.showError();
    },
  },
};
</script>

<style lang="scss" scoped>
.ping-btn {
  background: $sh-green-500;
  border-radius: 50px;
  color: $sh-neutral-900;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  height: 1.75rem;
  padding: 0.25rem 0.75rem;
  outline: none;
  .icon {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
}
.phone {
  margin-left: 20px;
}
.no-show-description {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  text-align: center;
  color: #737876;
  margin-top: 8px;
}
.no-show-question {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 28px;
  text-align: center;
  color: #000005;
  margin-top: 16px;
  margin-bottom: 0px;
}
.no-show-img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  border: 1.4px solid #dfe0df;
}
.busstop-and-passengers {
  margin-bottom: 16px;
}
.space-left {
  margin-left: 8px;
}
.shuttlers-location {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 3px;
}

.bus-stop {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 28px;
  color: #101211;
  margin-bottom: 0px;
}
.passenger-per-bus-stop {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 20px;
  /* Shuttlers Neutrals/600 */

  color: #737876;
}

.hidden {
  display: none;
}
</style>
