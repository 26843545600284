<template>
    <img :src="list_dot_2"/>
</template>

<script>
import list_dot_2 from '@/assets/icons/list-dot-2.svg'

export default {
  name: 'ListDot',
  data () {
    return {
      list_dot_2
    }
  }
}
</script>

<style scoped>
    img{
        margin-top: 6px;
    }
</style>
