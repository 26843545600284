<template>
  <div class="page-title">
    <img class="page-title__icon" :src="back"/>
    <span class="page-title__label">{{text}}</span>
  </div>
</template>

<script>
import back_btn from '../assets/icons/back.svg'
import router from '../router'
export default {
  name: 'BackHeader',
  props: {
    text: String
  },
  data () {
    return {
      back: back_btn
    }
  }
}
</script>

<style lang="scss" scoped>

.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #131615;
  margin: 0.75rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #EFF2F7;
  padding-bottom: 6px;
  position: relative;

  &__icon {
    margin-right: auto;
    z-index: 100;
  }

  &__label {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
</style>
