<template>
  <div>
    <page-title text=''/>
    <br><br>
    <div clsss="page-content-position">
      <div style="text-align: center;">
        <p class="no-show-question">
          <b>Scan QR code </b>
        </p>

        <p class="no-show-description">
          Scan the QR code to continue.
        </p>
    </div>
      <div style="text-align: center;">
        <span><QrcodeVue :value="`https://app.shuttlers.ng/routes/${routeId}/trips/${tripId}/tripDate/${tripDate}?pickupBusStop=${pickupBusStop}&routeCode=${routeCode}&isUpcoming=${isUpcoming}`" :size="size" level="H"></QrcodeVue></span>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import PageTitle from '@/components/PageTitle.vue';
export default {
  name: 'QRCodePage',
  components: {QrcodeVue, PageTitle},
  data () {
    return {
      value: '',
      size: 150,
      routeId: null,
      tripId: null,
      tripDate: null,
      pickupBusStop: null,
      routeCode: null,
      isUpcoming: null
    }
  },
  created() {
    this.setQRCodeParams();
  },
  methods: {
    setQRCodeParams() {
      this.routeId = this.$route.params.routeId;
      this.tripId = this.$route.params?.tripId;
      this.tripDate = this.$route.params?.tripDate;
      this.pickupBusStop = this.$route.params?.pickupBusStop;
      this.routeCode = this.$route.params?.routeCode;
      this.isUpcoming = this.$route.params?.isUpcoming;

      console.log(this.routeId)
    }
  }
}
</script>

<style scoped>
  .qrcode{
      margin-top: 54px;
  }
  canvas {
    padding: 20px;
    background: #FAFAFA;
    /* Elevation 3 */
    box-shadow: 0px 10px 24px rgba(33, 35, 37, 0.08);
    border-radius: 12px;
  }
  .page-content-position{
    margin-top: 50px;
  }
</style>
