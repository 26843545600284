<template>
    <div  v-if="from && to" class="horizontal-padding">
        <span style="width: 46%;">{{from.substring(0,17)}}..</span>
        <img style="width: 5%;" :src="arrow"/>
        <span style="width: 46%; text-align: right;">{{to.substring(0,17)}}..</span>
    </div>
</template>

<script>
import to_icon from '../assets/icons/to-icon.svg'
export default {
  name: 'PickupDropoff',
  props: {
    from: String,
    to: String
  },
  data () {
    return {
      arrow: to_icon
    }
  }
}
</script>

<style scoped>
    span{
        font-family: var(--font-roboto);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    div {
        /* Gray/Black 1 */

        background: #000005;
        color: #ffffff;
        position: relative;

        /*margin: -20px;*/
        /* padding: 20px; */
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 33px;
    }
    .horizontal-padding{
        padding-left: 20px;
        padding-right: 20px;
    }
</style>
